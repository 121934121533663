import React from "react";
import * as LS from "../../../styles/LearnerBadgesStyled";
import CardOne from "../../../assets/images/learner/Card-01.png";
import CardTwo from "../../../assets/images/learner/Card-02.png";
import CardThree from "../../../assets/images/learner/Card-03.png";
import CardFour from "../../../assets/images/learner/Card-04.png";
import CardFive from "../../../assets/images/learner/Card-05.png";
import CardSix from "../../../assets/images/learner/Card-06.png";
import CardSeven from "../../../assets/images/learner/Card-07.png";
import CardEight from "../../../assets/images/learner/Card-08.png";
import CardNine from "../../../assets/images/learner/Card-09.png";
import crossIcon from "../../../assets/images/crossIcon.png";
import { useSelector } from "react-redux";

const LearnerBadges = ({ theme, courseId, isClose, badgeData }) => {
  const selectLangJson = useSelector((state) => state?.selectLangJson);
  return (
    <LS.LearnerContainer themeColor={theme}>
      <LS.LeaderBadgHeader themeColor={theme}>
        <LS.LearnerHeader themeColor={theme}>
          <p className="head-title">{selectLangJson?.win_these_badges}</p>
          <img
            className="cross-icon"
            onClick={() => {
              isClose();
            }}
            src={crossIcon}
            alt=""
          />
        </LS.LearnerHeader>
      </LS.LeaderBadgHeader>
      <LS.LearnerCardBadge themeColor={theme}>
        {badgeData?.length > 0 ? (
          badgeData?.map((val) => {
            return (
              <LS.LearnerBadgesCard themeColor={theme}>
                <LS.CardImg themeColor={theme}>
                  <img src={val.logoImage} alt="Badges One" />
                </LS.CardImg>
                <LS.LBCardDetails themeColor={theme}>
                  <LS.LBCardTitile themeColor={theme}>
                    {val.title}
                  </LS.LBCardTitile>
                  <LS.LBCardSubtitle themeColor={theme}>
                    {val.discription}
                  </LS.LBCardSubtitle>
                </LS.LBCardDetails>
              </LS.LearnerBadgesCard>
            );
          })
        ) : (
          <LS.LearnerHeader themeColor={theme}>
            <p className="head-title">{selectLangJson?.no_badges}</p>
          </LS.LearnerHeader>
        )}

        {/* <LS.LearnerBadgesCard themeColor={theme}>
          <LS.CardImg themeColor={theme}>
            <img src={CardTwo} alt="Badges One" />
          </LS.CardImg>
          <LS.LBCardDetails themeColor={theme}>
            <LS.LBCardTitile themeColor={theme}>
              The One-Punch Hero
            </LS.LBCardTitile>
            <LS.LBCardSubtitle themeColor={theme}>
              First 5% learners to completed in the shortest time with 100%
              score (Single Attempt)
            </LS.LBCardSubtitle>
          </LS.LBCardDetails>
        </LS.LearnerBadgesCard>
        <LS.LearnerBadgesCard themeColor={theme}>
          <LS.CardImg themeColor={theme}>
            <img src={CardThree} alt="Badges One" />
          </LS.CardImg>
          <LS.LBCardDetails themeColor={theme}>
            <LS.LBCardTitile themeColor={theme}>
              The One-Punch Hero
            </LS.LBCardTitile>
            <LS.LBCardSubtitle themeColor={theme}>
              First 5% learners to completed in the shortest time with 100%
              score (Single Attempt)
            </LS.LBCardSubtitle>
          </LS.LBCardDetails>
        </LS.LearnerBadgesCard>
      </LS.LearnerCardMain>
      <LS.LearnerCardMain themeColor={theme}>
        <LS.LearnerBadgesCard themeColor={theme}>
          <LS.CardImg themeColor={theme}>
            <img src={CardFour} alt="Badges One" />
          </LS.CardImg>
          <LS.LBCardDetails themeColor={theme}>
            <LS.LBCardTitile themeColor={theme}>
              The One-Punch Hero
            </LS.LBCardTitile>
            <LS.LBCardSubtitle themeColor={theme}>
              First 5% learners to completed in the shortest time with 100%
              score (Single Attempt)
            </LS.LBCardSubtitle>
          </LS.LBCardDetails>
        </LS.LearnerBadgesCard>
        <LS.LearnerBadgesCard themeColor={theme}>
          <LS.CardImg themeColor={theme}>
            <img src={CardFive} alt="Badges One" />
          </LS.CardImg>
          <LS.LBCardDetails themeColor={theme}>
            <LS.LBCardTitile themeColor={theme}>
              The One-Punch Hero
            </LS.LBCardTitile>
            <LS.LBCardSubtitle themeColor={theme}>
              First 5% learners to completed in the shortest time with 100%
              score (Single Attempt)
            </LS.LBCardSubtitle>
          </LS.LBCardDetails>
        </LS.LearnerBadgesCard>
        <LS.LearnerBadgesCard themeColor={theme}>
          <LS.CardImg themeColor={theme}>
            <img src={CardSix} alt="Badges One" />
          </LS.CardImg>
          <LS.LBCardDetails themeColor={theme}>
            <LS.LBCardTitile themeColor={theme}>
              The One-Punch Hero
            </LS.LBCardTitile>
            <LS.LBCardSubtitle themeColor={theme}>
              First 5% learners to completed in the shortest time with 100%
              score (Single Attempt)
            </LS.LBCardSubtitle>
          </LS.LBCardDetails>
        </LS.LearnerBadgesCard>
      </LS.LearnerCardMain>
      <LS.LearnerCardMain themeColor={theme}>
        <LS.LearnerBadgesCard themeColor={theme}>
          <LS.CardImg themeColor={theme}>
            <img src={CardSeven} alt="Badges One" />
          </LS.CardImg>
          <LS.LBCardDetails themeColor={theme}>
            <LS.LBCardTitile themeColor={theme}>
              The One-Punch Hero
            </LS.LBCardTitile>
            <LS.LBCardSubtitle themeColor={theme}>
              First 5% learners to completed in the shortest time with 100%
              score (Single Attempt)
            </LS.LBCardSubtitle>
          </LS.LBCardDetails>
        </LS.LearnerBadgesCard>
        <LS.LearnerBadgesCard themeColor={theme}>
          <LS.CardImg themeColor={theme}>
            <img src={CardEight} alt="Badges One" />
          </LS.CardImg>
          <LS.LBCardDetails themeColor={theme}>
            <LS.LBCardTitile themeColor={theme}>
              The One-Punch Hero
            </LS.LBCardTitile>
            <LS.LBCardSubtitle themeColor={theme}>
              First 5% learners to completed in the shortest time with 100%
              score (Single Attempt)
            </LS.LBCardSubtitle>
          </LS.LBCardDetails>
        </LS.LearnerBadgesCard>
        <LS.LearnerBadgesCard themeColor={theme}>
          <LS.CardImg themeColor={theme}>
            <img src={CardNine} alt="Badges One" />
          </LS.CardImg>
          <LS.LBCardDetails themeColor={theme}>
            <LS.LBCardTitile themeColor={theme}>
              The One-Punch Hero
            </LS.LBCardTitile>
            <LS.LBCardSubtitle themeColor={theme}>
              First 5% learners to completed in the shortest time with 100%
              score (Single Attempt)
            </LS.LBCardSubtitle>
          </LS.LBCardDetails>
        </LS.LearnerBadgesCard> */}
      </LS.LearnerCardBadge>
    </LS.LearnerContainer>
  );
};

export default LearnerBadges;
