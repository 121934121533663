import dashLogo from "../../assets/images/sidebarIcon/lightDashboard.png";

import dashDark from "../../assets/images/sidebarIcon/dashDark.png";
import courseLogo from "../../assets/images/sidebarIcon/lightCourse.png";
import darkCourse from "../../assets/images/sidebarIcon/darkCourse.png";
import darkSupport from "../../assets/images/sidebarIcon/darkSupport.png";
import lightSupport from "../../assets/images/sidebarIcon/lightSupport.png";

export const SidebarData = [
  {
    title: "Dashboard",
    path: "/learner/dashboard",
    activeTab: ["/learner/dashboard"],
    icon: dashLogo,
    activeIcon: dashDark,
    moduleName: null,
  },
  {
    title: "Course",
    path: "/learner/course",
    activeTab: ["/learner/course"],
    icon: courseLogo,
    activeIcon: darkCourse,
    moduleName: null,
  },
  {
    title: "Support",
    path: "/assessment-quizz-management",
    activeTab: [
      "/assessment-quizz-management",
      "/add-assessment-quizz",
      "/interactive-quizz-management",
      "/add-interactive-quizz",
      "/add-free-quizz",
    ],
    icon: lightSupport,
    activeIcon: darkSupport,
    moduleName: null,
    key: ["interactive_quiz", "assessment_quiz"],
    subMenu: [
      {
        label: "Raise a Ticket",
        pathName: "/interactive-quizz-management",
        activePathTab: [
          "/interactive-quizz-management",
          "/add-interactive-quizz",
        ],
        key: "interactive_quiz",
      },
      {
        label: "Chat With Us",
        pathName: "/assessment-quizz-management",
        activePathTab: [
          "/assessment-quizz-management",
          "/add-assessment-quizz",
          "/add-free-quizz",
        ],
        key: "assessment_quiz",
      },
      {
        label: "OnBoarding Guide",
        pathName: "/assessment-quizz-management",
        activePathTab: [
          "/assessment-quizz-management",
          "/add-assessment-quizz",
          "/add-free-quizz",
        ],
        key: "assessment_quiz",
      },
    ],
  },
];
