import React, { useEffect, useState } from "react";
import * as CS from "../../../styles/CourseViewStyled";
import * as HS from "../../../styles/HeaderStyled";
import langIcon from "../../../assets/images/langIcon.png";
import darkLang from "../../../assets/images/darkLang.png";
import darkDonwArrow from "../../../assets/images/darkDonwArrow.png";
import downIcon from "../../../assets/images/downIcon.png";
import medalIcon from "../../../assets/images/medalIcon.png";
import cupIcon from "../../../assets/images/cupIcon.png";
import googleIcon from "../../../assets/images/googleIcon.png";
import lightTrophy from "../../../assets/images/lightTrophy.png";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { useSelector, useDispatch } from "react-redux";
import SelectQues from "../../../components/SelectQues";
import axios from "../../../utils/axios";
import { get } from "lodash";
import {
  setBannerThumb,
  setButtonName,
  setEpisodeCompleted,
  setEpisodedSelectLang,
  setEpisodeId,
  setEpisodeLanguages,
  setErrorPop,
  setErrorPopMsg,
  setTemplateId,
  setVideoPlay,
} from "../../../store/action";
import { Badges, Course, Episode } from "../../../utils/api";
import Modal from "../../../components/Modal/Modal";
import { colorProvider } from "../../../utils/StylePropertiy";
import LearnerBadges from "./LearnerBadges";
import ModalComplete from "../../../components/Modal/ModalComplete";
import * as QS from "../../../styles/QuizStyled";
import * as SEC from "../../../styles/SectionStyledTwo";
import successModal from "../../../assets/images/warns.png";
import { QUIZEPISODE } from "../../../store/actionTypes";

const HeaderView = ({ theme, headData }) => {
  const dispatch = useDispatch();
  const completeProgress = useSelector((state) => state?.completeProgress);
  const selectLangJson = useSelector((state) => state?.selectLangJson);
  const bannerThumb = useSelector((state) => state?.bannerThumb);
  const [headMenuData, setHeadMenuData] = useState(headData);
  const episodeLanguages = useSelector((state) => state?.episodeLanguages);
  const episodeSelectLang = useSelector((state) => state?.episodeSelectLang);
  const courseId = useSelector((state) => state?.courseId);
  const [isBadges, setIsBadges] = useState(false);
  const [badgeData, setBadgeData] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState(episodeSelectLang);
  const [tempLang, setTempLang] = useState("");
  const [isPopPo, setIsPopPo] = useState(false);
  const selectLang = useSelector((state) => state?.selectLang);
  const menuRefresh = useSelector((state) => state?.menuRefresh);
  const [sideLangId, setSideLangId] = useState(selectLang);
  const [menuLoading, setMenuLoading] = useState(false);
  const [bannerLoading, setBannerLoading] = useState(false);

  // const [selectTopLang, setSelectTopLang] = useState(episodeSelectLang);

  const getBadges = async () => {
    try {
      let url = `${Badges.getBadges}${courseId}`;
      const { data } = await axios.get(url);
      if (data?.data) {
        setBadgeData(data?.data);
      } else {
        setBadgeData([]);
      }
    } catch (error) {
      console.log(error.response.data.message);
      if (error.response && error.response.status === 400) {
        dispatch(setErrorPop(true));
        dispatch(setErrorPopMsg(error?.response?.data?.message));
      }
    }
  };
  useEffect(() => {
    if (courseId) {
      getBadges();
    }
  }, [courseId]);

  const handleClose = () => {
    setIsBadges(false);
  };

  const getCourseDetail = async (e) => {
    let url = Course.getCourseDetail;
    try {
      const { data } = await axios.post(url, {
        courseId: courseId,
        languageId: sideLangId,
      });
      if (data?.result) {
        dispatch(
          setEpisodeLanguages(
            data?.result[0]?.languages?.map((val) => {
              return {
                label: val.title,
                value: val._id,
              };
            })
          )
        );
        dispatch(
          setEpisodedSelectLang(get(data?.result[0], "languages[0]._id"))
        );
        setSelectedLanguage(get(data?.result[0], "languages[0]._id"));
      }
    } catch (error) {
      console.log(error.response.data.message);
      if (error.response && error.response.status === 400) {
        dispatch(setErrorPop(true));
        dispatch(setErrorPopMsg(error?.response?.data?.message));
      }
    }
  };

  const getCourseMenu = async () => {
    setMenuLoading(true);
    let url = Course.getCourseMenu;
    try {
      const { data } = await axios.post(url, {
        courseId: courseId,
        languageId: selectLang,
      });
      setMenuLoading(false);
      if (data?.data) {
        setHeadMenuData(data?.data);
        dispatch(setTemplateId(data?.data?.learnerCertificateId));
      }
    } catch (error) {
      setMenuLoading(false);
      console.log(error.response.data.message);
      if (error.response && error.response.status === 400) {
        dispatch(setErrorPop(true));
        dispatch(setErrorPopMsg(error?.response?.data?.message));
      }
    }
  };

  useEffect(() => {
    if (!episodeSelectLang) {
      if (courseId && selectLang) {
        getCourseDetail();
      }
    }
  }, [courseId, selectLang]);
  const handleConfirmChangeLanguage = () => {
    dispatch(setEpisodedSelectLang(tempLang));
    setSelectedLanguage(tempLang);
    setIsPopPo(false);
  };

  const handleClosePopup = () => {
    setIsPopPo(false);
  };

  useEffect(() => {
    if (courseId) {
      getCourseMenu();
      // getLeaderBoard();
    }
  }, [courseId, menuRefresh]);

  const startLearning = async () => {
    setBannerLoading(true);
    let url = Episode.startLearning;
    try {
      const { data } = await axios.post(url, {
        courseId: courseId,
        languageId: episodeSelectLang,
      });
      setBannerLoading(false);
      if (data?.data) {
        let comData = {
          categorie: get(data?.data, "course_categorie", ""),
          courseTitle: get(data?.data, "course_name", ""),
          thumbUrl: get(data?.data, "course_baise_infos.thumbnailImage", ""),
          bannerTrailerImageNameUrl: get(
            data?.data,
            "course_baise_infos.bannerTrailerImageNameUrl",
            ""
          ),
          basicDescription: get(
            data?.data,
            "course_baise_infos.baiseDescription",
            ""
          ),
          client_logo: get(data?.data, "clientLogo", ""),
        };
        dispatch(setBannerThumb(comData));
      }
    } catch (error) {
      console.log(error.response.data);
      if (error.response && error.response.status === 400) {
        if (error.response.data?.data?.HOME_SCREEN) {
          dispatch(setVideoPlay(false));
        }
      }
      if (error.response && error.response.status === 400) {
        dispatch(setErrorPop(true));
        dispatch(setErrorPopMsg(error?.response?.data?.message));
      }
      setBannerLoading(false);
    }
  };

  
    useEffect(() => {
      if (courseId && episodeSelectLang) {
        startLearning();
      }
    }, [courseId, episodeSelectLang]);

  return (
    <CS.CourseInnerHeader themeColor={theme}>
      <CS.HeaderContainer themeColor={theme}>
        <CS.FirstHeader themeColor={theme}>
          <p className="title">{get(bannerThumb, "courseTitle", "")}</p>
          <p className="desc">{get(bannerThumb, "categorie", "")}</p>
        </CS.FirstHeader>
        <HS.ComIconContainersDowns themeColor={theme}>
          <img src={theme ? darkLang : langIcon} alt="" />
          <SelectQues
            options={episodeLanguages}
            star={false}
            value={episodeLanguages?.find(
              (val) => val.value === selectedLanguage
            )}
            placeholder="Select"
            onChange={(e) => {
              setIsPopPo(true);
              // setSelectedLanguage(e.value);
              setTempLang(e.value);
            }}
          />
        </HS.ComIconContainersDowns>
      </CS.HeaderContainer>
      <HS.SecondContainer>
        <HS.BorderContainerTwo
          themeColor={theme}
          className="resp"
        ></HS.BorderContainerTwo>
        <HS.ComIconContainer themeColor={theme}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {" "}
            <HS.CircleBox>
              <CircularProgressbar
                value={completeProgress}
                strokeWidth={"14"}
                styles={buildStyles({
                  strokeLinecap: "butt",
                  pathTransitionDuration: 0.5,
                  pathColor: `#17B26A`,
                  textSize: "16px",
                  position: "relative",
                  trailColor: theme ? "#333741" : "#D7DAE0",
                })}
              />
              <img
                src={theme ? cupIcon : lightTrophy}
                alt="Your Alt Text"
                style={{
                  position: "absolute",
                  width: "13px", // Adjust size accordingly
                  height: "13px", // Adjust size accordingly
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              />
            </HS.CircleBox>
            <HS.CircleText themeColor={theme}>
              {completeProgress}%{" "}
              {window.location.pathname === "/learner/courses/certificate"
                ? selectLangJson?.completed
                : selectLangJson?.progress}
            </HS.CircleText>
          </div>
        </HS.ComIconContainer>
        {headMenuData?.googleReview ? (
          <HS.BorderContainer themeColor={theme}></HS.BorderContainer>
        ) : (
          ""
        )}

        {headMenuData?.googleReview ? (
          <HS.GoogleContainer
            target="_blank"
            href={headMenuData?.review}
            themeColor={theme}
          >
            <img src={googleIcon} alt="" />
            <HS.CircleText themeColor={theme}>
              {selectLangJson?.leave_a_review}
            </HS.CircleText>
          </HS.GoogleContainer>
        ) : (
          ""
        )}
        {headMenuData?.badges ? (
          <HS.BorderContainer themeColor={theme}></HS.BorderContainer>
        ) : (
          ""
        )}

        {headMenuData?.badges ? (
          <HS.MedalImage
            onClick={() => {
              setIsBadges(true);
            }}
            themeColor={theme}
          >
            <img src={medalIcon} alt="" />
          </HS.MedalImage>
        ) : (
          ""
        )}
      </HS.SecondContainer>
      <Modal
        maxWidth={"950px"}
        isOpen={isBadges}
        backColor={colorProvider.darkBlue}
        theme={theme}
        component={
          <LearnerBadges
            isClose={handleClose}
            theme={theme}
            badgeData={badgeData}
          />
        }
      />
      <ModalComplete
        isOpen={isPopPo}
        maxWidth="400px"
        theme={theme}
        component={
          <SEC.InnerSectionSuccessSoEpCom
            themeColor={theme}
            style={{ padding: "1rem 0" }}
          >
            <SEC.SuccessIconBoxSo>
              <img src={theme ? successModal : successModal} alt="danger" />
            </SEC.SuccessIconBoxSo>
            <SEC.TitleSo themeColor={theme}>
              {selectLangJson?.warning}
            </SEC.TitleSo>
            <SEC.Subtitle themeColor={theme}>
              {selectLangJson?.if_you_change_the_video_language}
            </SEC.Subtitle>
            <QS.ButtonGroup style={{ width: "300px" }} themeColor={theme}>
              <QS.CancelButton
                style={{ width: "50%" }}
                themeColor={theme}
                onClick={() => {
                  handleClosePopup();
                  setSelectedLanguage(selectedLanguage);
                }}
              >
                {selectLangJson?.cancel}
              </QS.CancelButton>
              <QS.SaveButton
                style={{ width: "50%" }}
                themeColor={theme}
                onClick={handleConfirmChangeLanguage}
              >
                {selectLangJson?.ok_continue}
              </QS.SaveButton>
            </QS.ButtonGroup>
          </SEC.InnerSectionSuccessSoEpCom>
        }
      />
    </CS.CourseInnerHeader>
  );
};

export default HeaderView;
