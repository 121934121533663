import styled from "styled-components/macro";
import {
  commonStyles,
  colorProvider,
  fontProvider,
  fontWeight,
} from "../utils/StylePropertiy";
import checkboxIcon from "../assets/images/Checkbox.png";
import checkboxBaseIcon from "../assets/images/_Checkbox_base.png";
import darkCheckboxBaseIcon from "../assets/images/dark_base.png";
import darkcheckIcon from "../assets/images/darkcheckIcon.png";
import checkbox_minus_light from "../assets/images/checkbox_minus_light.png";
import checkbox_minus_dark from "../assets/images/checkbox_minus_dark.png";

export const VideoPlayerOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 9999999999999999999999999999999999999999;
  background-color: white;

  .video-ele {
    height: 100%;
    width: 100%;
  }
`;
export const MediaContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
`;
export const TitleSo = styled.div`
  width: 100%;
  font-size: 1.2rem;
  color: ${(props) => (props.themeColor ? "#F5F5F6" : "#24262D")};
  font-weight: 600;
  margin-bottom: 1rem;
`;
export const SuccessIconBoxSo = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;

  img {
    height: 60px;
    width: 60px;
    object-fit: contain;
  }
`;
export const InnerSectionSuccess = styled.div`
  width: 100%;
  text-align: center;
  width: 300px;
`;
export const BtnClose = styled.button`
  width: 100%;
  margin-top: 1.8rem;
  margin-bottom: 1.8rem;
  background-color: ${(props) =>
    props.themeColor ? "#0C111D" : "transparent"};
  color: ${(props) => (props.themeColor ? "#CECFD2" : "#000000")};
  box-shadow: ${(props) =>
    props.themeColor ? "0px 1px 2px 0px #1018280D" : ""};
  border: ${(props) =>
    props.themeColor ? "1px solid #333741" : "1px solid #b3b9c6"};
  padding: 1.6rem 0;
  line-height: 0;
  border-radius: 0.5rem;
  font-size: 1.2rem;
  font-weight: 600;
  border-radius: 8px;
  cursor: pointer;
`;
export const HomeContainer = styled.div`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  flex-direction: ${commonStyles.fd_col};
  justify-content: ${commonStyles.jc_c};
  align-items: ${commonStyles.ai_c};
  padding-bottom: 1.5rem;
  /* @media (max-width: 1450px) {
    padding: 1.5rem;
  } */
  /* @media (max-width: 769px) {
    padding: 1.5rem;
  } */
`;
export const MainSection = styled.section`
  width: ${commonStyles.wid};
  max-width: 1350px;
  display: ${commonStyles.ds_fx};
  /* justify-content: ${commonStyles.jc_c};
  align-items: ${commonStyles.ai_c}; */
  flex-direction: ${commonStyles.fd_col};
  margin-top: 0.5rem;

  .horizontal-scroll {
    overflow-x: scroll;
    white-space: nowrap;
    width: 100%;
    margin-top: 1.5rem;
  }

  .cards-container {
    display: flex;
    flex-wrap: wrap;
    // width: 660px;
    width: 100%;
  }

  .card {
    flex: 0 0 420px;
    margin-right: 1rem;
    /* background-color: #f0f0f0; */
    /* padding: 20px; */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: ${commonStyles.ds_fx};
    flex-direction: ${commonStyles.fd_col};
    justify-content: ${commonStyles.jc_fs};
    align-items: ${commonStyles.jc_fs};
    box-shadow: 0px 2px 4px -1px #0000000f, 0px 4px 6px -1px #0000001a;
    @media (max-width: 500px) {
      flex: 0 0 100%;
    }
    border: ${(props) =>
      props.themeColor
        ? `1px solid ${colorProvider.blackFive}`
        : commonStyles.course_bor};
    border-radius: ${commonStyles.sec_br};
    transition: transform 0.3s linear;
    position: relative;
  }

  .card-image {
    width: 430px;
    height: 240px;
    object-fit: cover;
    display: block;
  }

  .horizontal-scroll::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
  }
`;
export const MainSectionTwo = styled.section`
  width: ${commonStyles.wid};
  max-width: 1350px;
  display: ${commonStyles.ds_fx};
  /* justify-content: ${commonStyles.jc_c};
  align-items: ${commonStyles.ai_c}; */
  flex-direction: ${commonStyles.fd_col};
  margin-top: 0.5rem;
  background-color: ${(props) =>
    props.themeColor ? colorProvider.darkBlue : colorProvider.white};

  .horizontal-scroll {
    overflow: auto;
    white-space: nowrap;
    width: 100%;
    /* height: 300px; */
    display: flex;
    align-items: center;
    padding: 2rem 0;
    /* margin-top: 1.5rem; */
  }

  .cards-container {
    display: flex;
    // width: 660px;
    @media (max-width: 500px) {
      flex: 0 0 100%;
    }
  }

  .card-image {
    width: 430px;
    height: 240px;
    object-fit: cover;
    display: block;
  }

  .horizontal-scroll::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
  }
`;
export const InnerSection = styled.div`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  /* justify-content: ${commonStyles.jc_c};
  align-items: ${commonStyles.ai_c}; */
  flex-direction: ${commonStyles.fd_col};
  position: relative;
`;
export const BannerContainer = styled.div`
  position: relative;
  height: 700px;
  width: ${commonStyles.wid};
  max-width: 1350px;
  @media (max-width: 769px) {
    height: 500px;
  }
`;
export const BannerContainerThree = styled.div`
  position: relative;
  height: 700px;
  width: ${commonStyles.wid};
  max-width: 1350px;
  @media (max-width: 769px) {
    height: 500px;
  }
`;
export const BannerContainerTwo = styled.div`
  position: relative;
  height: 750px;
  width: ${commonStyles.wid};
  max-width: 1350px;
  @media (max-width: 769px) {
    height: 430px;
  }
`;
export const BackImage = styled.div`
  width: ${commonStyles.wid};
  position: absolute;

  top: 0;

  img {
    width: ${commonStyles.wid};
    height: 700px;
    object-fit: fill;
    @media (max-width: 768px) {
      height: 500px;
    }
  }

  .video-background-auto {
    object-fit: cover;
  }
  .transBack {
    width: ${commonStyles.wid};
    height: 750px;
    display: ${commonStyles.ds_fx};
    align-items: ${commonStyles.jc_fe};
    justify-content: ${commonStyles.jc_c};
    padding: 0 2rem;
    position: absolute;
    top: 0;
    @media (max-width: 768px) {
      height: 500px;
      align-items: ${commonStyles.jc_fs};
      padding: 0px 1.5rem 0px 1.5rem;
    }
    background: linear-gradient(
      360deg,
      #0c111d 0%,
      rgba(12, 17, 29, 0.96) 11.96%,
      rgba(12, 17, 29, 0.72) 29.53%,
      rgba(12, 17, 29, 0.3734) 40.5%,
      rgba(12, 17, 29, 0) 52.02%
    );
  }
`;
export const BackImageTwo = styled.div`
  width: ${commonStyles.wid};
  position: absolute;

  top: 0;

  img {
    width: ${commonStyles.wid};
    height: 700px;
    object-fit: cover;
    @media (max-width: 768px) {
      height: 500px;
    }
  }

  .video-background-auto {
    object-fit: cover;
  }
  .transBack {
    width: ${commonStyles.wid};
    height: 700px;
    display: ${commonStyles.ds_fx};
    align-items: ${commonStyles.jc_fe};
    justify-content: ${commonStyles.jc_c};
    padding: 0 2rem;
    position: absolute;
    top: 0;
    @media (max-width: 768px) {
      height: 500px;
      align-items: ${commonStyles.jc_fs};
      padding: 0px 1.5rem 0px 1.5rem;
    }
    background: linear-gradient(
      360deg,
      #0c111d 0%,
      rgba(12, 17, 29, 0.96) 11.96%,
      rgba(12, 17, 29, 0.72) 29.53%,
      rgba(12, 17, 29, 0.3734) 40.5%,
      rgba(12, 17, 29, 0) 52.02%
    );
  }
`;
export const OtherDatasContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 1450px) {
    padding: 1.5rem;
  }
`;
export const CourseCard = styled.div`
  width: 95%;
  display: ${commonStyles.ds_fx};
  flex-direction: ${commonStyles.fd_col};
  position: relative;
  border-radius: ${commonStyles.sec_br};
`;
export const CourseImage = styled.div`
  height: ${commonStyles.het};
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.jc_c};
  align-items: ${commonStyles.ai_c};
  border-radius: ${commonStyles.sec_br} ${commonStyles.sec_br} 0 0;

  img {
    height: ${commonStyles.het};
    width: ${commonStyles.wid};
    object-fit: ${commonStyles.ob_con};
    border-radius: ${commonStyles.sec_br} ${commonStyles.sec_br} 0 0;
  }
`;
export const TrailerRow = styled.div`
  width: ${commonStyles.wid};
  display: flex;
  justify-content: flex-end;
  align-items: center;
  opacity: 0;
  visibility: visible;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  position: absolute;
  margin-top: -2rem;
  right: 0;
  /* transform: translateX(-50%); */
  z-index: 10;
`;
export const CourseDescriptionTwo = styled.div`
  width: 100%;
  font-size: ${fontProvider.zeroEig};
  font-weight: ${fontWeight.five};
  color: ${(props) =>
    props.themeColor ? colorProvider.greyThree : colorProvider.comLink};
  text-transform: capitalize;
  line-height: 17px;
  // display: none;
  overflow: hidden; // Prevents content from spilling over
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  -webkit-line-clamp: 2; // Limits the content to two lines
  -webkit-box-orient: vertical; // Sets the direction of content flow to vertical
  text-overflow: ellipsis; // Adds an ellipsis when the text overflows
  max-height: 34px; // Double the line-height to ensure only two lines are displayed
`;
export const CourseTitle = styled.div`
  width: 80%;
  font-size: ${fontProvider.oneTwo};
  font-weight: ${fontWeight.six};
  color: ${(props) =>
    props.themeColor ? colorProvider.white : colorProvider.blueColor};
  font-family: EB Garamond;
  margin-bottom: 4px;
  text-transform: capitalize;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media (max-width: 768px) {
    font-size: ${fontProvider.zeroTen};
  }

  span {
    font-family: EB Garamond;
    font-weight: ${fontWeight.eight};
  }
`;
export const ParentCard = styled.div`
  flex: 0 0 420px;
  width: 200px;
  margin-right: 1rem;
  /* background-color: #f0f0f0; */
  /* padding: 20px; */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: ${commonStyles.ds_fx};
  flex-direction: ${commonStyles.fd_col};
  justify-content: ${commonStyles.jc_fs};
  align-items: ${commonStyles.jc_fs};
  box-shadow: 0px 2px 4px -1px #0000000f, 0px 4px 6px -1px #0000001a;
  border: ${(props) =>
    props.themeColor
      ? `1px solid ${colorProvider.blackFive}`
      : commonStyles.course_bor};
  border-radius: ${commonStyles.sec_br};
  transition: transform 0.3s linear;
  position: relative;
  :hover {
    transform: scale(1.2);
    z-index: 2;
    margin-left: ${(props) => (props.index === 0 ? "2.7rem" : "0")};
  }
  &:hover ${TrailerRow} {
    opacity: 1; // Make the trailer row fully opaque
    visibility: visible; // Make the trailer row visible
  }
  &:hover ${CourseDescriptionTwo} {
    opacity: 1; // Make the trailer row fully opaque
    display: -webkit-box; // Uses the webkit line clamp feature
  }
  &:hover ${CourseTitle} {
    color: ${(props) =>
      props.themeColor ? colorProvider.navLink : colorProvider.navLink};
  }
`;
export const ParentCardCategory = styled.div`
  width: 420px;
  margin-right: 1rem;
  /* background-color: #f0f0f0; */
  /* padding: 20px; */
  // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: ${commonStyles.ds_fx};
  flex-direction: ${commonStyles.fd_col};
  justify-content: ${commonStyles.jc_fs};
  align-items: ${commonStyles.jc_fs};
  // box-shadow: 0px 2px 4px -1px #0000000f, 0px 4px 6px -1px #0000001a;
  @media (max-width: 500px) {
    width: 100%;
    flex: 0 0 100%;
  }
  box-shadow: ${(props) =>
    props.themeColor
      ? `none`
      : `0px 1px 2px 0px #0000000F, 0px 1px 3px 0px #0000001A;`};
  border: ${(props) =>
    props.themeColor
      ? `1px solid ${colorProvider.blackFive}`
      : commonStyles.course_bor};
  /* border: 2px solid red; */
  border-radius: ${commonStyles.sec_br};
  // transition: transform 0.3s linear;
  transition: all 350ms;
  position: relative;
  overflow: hidden;
  :hover {
    // transform: scale(1.1);
    z-index: 2;
    // margin-left: ${(props) => (props.index === 0 ? "2.7rem" : "0")};
  }
  &:hover .video-class-element {
    transition: all 350ms;
    transform: scale(1.1);
  }
  &:hover ${TrailerRow} {
    opacity: 1; // Make the trailer row fully opaque
    visibility: visible; // Make the trailer row visible
  }
  &:hover ${CourseDescriptionTwo} {
    opacity: 1; // Make the trailer row fully opaque
    display: -webkit-box; // Uses the webkit line clamp feature
  }
  &:hover ${CourseTitle} {
    color: ${(props) =>
      props.themeColor ? colorProvider.navLink : colorProvider.navLink};
  }
`;
export const CardImageTwoCourse = styled.div`
  width: 420px;
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.jc_c};
  flex-direction: ${commonStyles.fd_col};
  align-items: ${commonStyles.jc_c};
  overflow: hidden;
  @media (max-width: 500px) {
    width: 100%;
  }
  img {
    height: 135px;
    width: 100%;
    border-radius: 8px 8px 0 0;
    /* object-fit: ${commonStyles.ob_con}; */
    object-fit: cover;
    // transition: transform 0.3s linear;
    transition: 350ms;
  }

  .video-class-element {
    height: 170px;
    width: calc(100% - 2px);
    object-fit: cover;
    border-radius: 8px 8px 0 0;
    transition: all 350ms;
  }
`;
export const CourseContent = styled.div`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  flex-direction: ${commonStyles.fd_col};
  padding: 0.8rem;
  border-radius: 8px;
  background-color: ${(props) =>
    props.themeColor ? colorProvider.darkBlue : colorProvider.white};
`;

export const CourseDescription = styled.div`
  font-size: ${fontProvider.zeroEig};
  font-weight: ${fontWeight.five};
  color: ${(props) =>
    props.themeColor ? colorProvider.greyThree : colorProvider.comLink};
  text-transform: capitalize;
  line-height: 17px;
  text-transform: capitalize;
  margin-bottom: 10px;
`;
export const ViewDetails = styled.div`
  font-size: ${fontProvider.zeroNin};
  font-weight: ${fontWeight.six};
  color: ${colorProvider.orange};
  text-transform: capitalize;
  margin-top: 7px;
`;
export const BorderTitle = styled.div`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  align-items: ${commonStyles.ai_c};
  justify-content: ${commonStyles.jc_sb};
  border-bottom: ${(props) =>
    props.themeColor
      ? `1px solid ${colorProvider.blackTwo}`
      : commonStyles.com_b};
  padding-bottom: 0.8rem;
  margin-top: 2rem;

  .font-style {
    font-family: EB Garamond;
  }
  p {
    font-size: ${fontProvider.zeroTen};
    font-weight: ${fontWeight.six};
    color: ${(props) =>
      props.themeColor ? colorProvider.greyFour : colorProvider.blackCol};
  }
  .view-all {
    font-size: ${fontProvider.zeroEig};
    font-weight: ${fontWeight.six};
    cursor: ${commonStyles.pointer};
    color: ${(props) =>
      props.themeColor ? colorProvider.greyThree : colorProvider.greyThree};
  }
`;
export const MediaCardsBox = styled.div`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.jc_fs};
  align-items: ${commonStyles.ai_c};
  margin-top: 1.3rem;
  gap: 1.5rem;
  cursor: pointer;
`;
export const MediaCard = styled.div`
  width: 32%;
  min-width: 430px;
  display: ${commonStyles.ds_fx};
  flex-direction: ${commonStyles.fd_col};
  position: relative;
  border-radius: ${commonStyles.sec_br};
  @media (max-width: 768px) {
    min-width: 280px;
  }
`;
export const CardImage = styled.div`
  height: ${commonStyles.het};
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.jc_c};
  align-items: ${commonStyles.ai_c};
  border-radius: ${commonStyles.sec_br};

  img {
    height: 220px;
    width: ${commonStyles.wid};
    object-fit: cover;
    border-radius: ${commonStyles.sec_br};
  }
  .video-class-element {
    object-fit: cover;
    border-radius: 12px;
  }
`;
export const CardImageContinue = styled.div`
  height: ${commonStyles.het};
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.jc_c};
  align-items: ${commonStyles.ai_c};
  border-radius: ${commonStyles.sec_br};

  img {
    height: 220px;
    width: 420px;
    object-fit: cover;
    border-radius: ${commonStyles.sec_br};
  }
  .video-class-element {
    height: 220px;
    width: 420px;
    object-fit: cover;
    border-radius: 12px;
  }
`;
export const CardContent = styled.div`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  flex-direction: ${commonStyles.fd_col};
  position: absolute;
  bottom: 0;
`;
export const InnerContent = styled.div`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  flex-direction: ${commonStyles.fd_col};
  background-color: ${colorProvider.transColor};
  border-radius: 0 0 ${commonStyles.sec_br} ${commonStyles.sec_br};
  padding: 1rem;
  backdrop-filter: blur(20px);
  cursor: pointer;
`;
export const CartTitleRow = styled.div`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.jc_sb};
  align-items: ${commonStyles.ai_c};
`;
export const CartTitle = styled.div`
  font-size: ${fontProvider.zeroTen};
  font-weight: ${fontWeight.four};
  color: ${colorProvider.white};
  font-family: EB Garamond;
  text-transform: capitalize;
  @media (max-width: 768px) {
    font-size: ${fontProvider.zeroNin};
  }
  span {
    font-family: EB Garamond;
    font-weight: ${fontWeight.eight};
  }
`;
export const CartLeftTime = styled.div`
  font-size: ${fontProvider.zeroSev};
  font-weight: ${fontWeight.six};
  color: ${colorProvider.white};
  @media (max-width: 768px) {
    font-size: ${fontProvider.zeroSix};
  }
`;
export const CartDescRow = styled.div`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.jc_sb};
  align-items: ${commonStyles.ai_c};
  margin-top: 5px;
`;
export const CartDescription = styled.div`
  font-size: ${fontProvider.zeroSev};
  font-weight: ${fontWeight.five};
  color: ${colorProvider.greyTer};
  @media (max-width: 768px) {
    font-size: ${fontProvider.zeroSix};
  }
`;
export const CartDeadLine = styled.div`
  font-size: ${fontProvider.zeroSev};
  font-weight: ${fontWeight.six};
  color: ${colorProvider.warOrange};
  @media (max-width: 768px) {
    font-size: ${fontProvider.zeroSix};
  }
`;
export const ContentBox = styled.div`
  height: ${commonStyles.wid};
  width: ${commonStyles.wid};
  max-width: 1350px;
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.jc_sb};
  align-items: ${commonStyles.jc_fe};
  padding-bottom: 6rem;
  @media (max-width: 768px) {
    padding-bottom: 1rem;
  }
`;
export const FirstBox = styled.div`
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.jc_fs};
  align-items: ${commonStyles.jc_fs};
  flex-direction: ${commonStyles.fd_col};
  @media (max-width: 768px) {
    padding-left: 0rem;
  }
`;
export const SecondBox = styled.div`
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.jc_fe};
  align-items: ${commonStyles.jc_fe};
  flex-direction: ${commonStyles.fd_col};
`;
export const NewlyRow = styled.div`
  display: ${commonStyles.ds_fx};
  background-color: ${colorProvider.greySeven};
  border-radius: ${commonStyles.nav_br};
  border-left: ${commonStyles.orange_bor};
  padding: ${fontProvider.zeroEig} ${fontProvider.zeroTen};

  p {
    color: ${colorProvider.white};
    font-size: ${fontProvider.zeroEig};
    font-weight: ${fontWeight.six};
    @media (max-width: 768px) {
      font-size: ${fontProvider.zeroSev};
    }
  }
`;
export const BannerTitle = styled.div`
  margin-top: ${fontProvider.zeroFour};
  p {
    color: ${colorProvider.white};
    font-size: ${fontProvider.twoThree};
    font-weight: ${fontWeight.seven};
    font-family: EB Garamond;
    text-transform: capitalize;
    @media (max-width: 768px) {
      font-size: 1.8rem;
    }

    @media (max-width: 780px) {
      text-align: center;
    }
    @media (max-width: 500px) {
      padding: 0 1rem;
    }
    @media (max-width: 425px) {
      font-size: 1.4rem;
    }
  }
`;
export const BannerTitleTwo = styled.div`
  margin-top: ${fontProvider.zeroFour};
  p {
    color: ${colorProvider.white};
    font-size: ${fontProvider.twoThree};
    font-weight: ${fontWeight.seven};
    font-family: EB Garamond;
    text-transform: capitalize;
    @media (max-width: 768px) {
      font-size: 1.8rem;
    }
    @media (max-width: 425px) {
      font-size: 1.4rem;
    }
  }
`;
export const BannerDesc = styled.div`
  width: 58%;
  p {
    color: ${colorProvider.whiteFour};
    font-size: ${fontProvider.zeroEig};
    font-weight: ${fontWeight.four};
    line-height: 20px;
    text-align: ${commonStyles.jc_c};
    margin-top: ${fontProvider.zeroThree};
    margin-bottom: ${fontProvider.oneThree};
    text-transform: none;

    @media (max-width: 780px) {
      display: -webkit-box;
      -webkit-line-clamp: 2; /* Limits to 2 lines */
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
    }
    @media (max-width: 425px) {
      font-size: 0.6rem;
    }
  }
`;
export const BannerDescTwo = styled.div`
  width: 58%;
  p {
    color: ${colorProvider.whiteFour};
    font-size: ${fontProvider.zeroEig};
    font-weight: ${fontWeight.four};
    line-height: 20px;
    text-align: ${commonStyles.jc_c};
    margin-top: ${fontProvider.zeroThree};
    margin-bottom: ${fontProvider.oneThree};
    text-transform: none;
    text-align: left;

    @media (max-width: 780px) {
      display: -webkit-box;
      -webkit-line-clamp: 2; /* Limits to 2 lines */
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
    }
    @media (max-width: 425px) {
      font-size: 0.7rem;
    }
  }
  @media (max-width: 780px) {
    width: 100%;
  }
`;
export const WatchButton = styled.div`
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.jc_c};
  align-items: ${commonStyles.jc_c};
  background-color: ${colorProvider.navLink};
  border-radius: ${commonStyles.sec_br};
  padding: ${fontProvider.zeroSix} ${fontProvider.zeroEig};
  gap: ${fontProvider.zeroFive};
  cursor: ${commonStyles.pointer};

  p {
    color: ${colorProvider.white};
    font-size: ${fontProvider.zeroEig};
    font-weight: ${fontWeight.five};
    @media (max-width: 768px) {
      font-size: ${fontProvider.zeroSev};
    }
  }

  img {
    height: 18px !important;
    width: 18px !important;
    object-fit: ${commonStyles.ob_con};
  }
`;
export const SoundIconT = styled.img`
  height: auto !important;
  width: 25% !important;
  object-fit: ${commonStyles.ob_con};
  cursor: pointer;
`;
export const ContentRow = styled.div`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.jc_sb};
  margin-bottom: 0px;
  align-items: ${commonStyles.jc_c};
`;
export const ComContentRow = styled.div`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  flex-direction: ${commonStyles.fd_col};
  cursor: ${commonStyles.pointer};
  align-items: ${commonStyles.jc_sb};
`;
export const RenewButton = styled.button`
  border: none;
  outline: none;
  cursor: pointer;
  background-color: ${colorProvider.orange};
  font-size: ${fontProvider.zeroEig};
  font-weight: ${fontWeight.six};
  color: ${colorProvider.white};
  border-radius: ${commonStyles.sec_br};
  text-align: ${commonStyles.jc_c};
  padding: ${fontProvider.zeroFive} ${fontProvider.zeroTen};
`;
export const SubsCard = styled.div`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  align-items: ${commonStyles.jc_c};
  gap: 1.5rem;
  margin-top: 1.3rem;
  overflow-x: scroll;
  margin-left: 2rem;
  max-width: 1380px;

  @media (max-width: 768px) {
    margin-left: 0rem;
    align-items: flex-start;
  }
  ::-webkit-scrollbar {
    display: none;
  }
`;
export const SubsCards = styled.div`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  align-items: ${commonStyles.jc_c};
  gap: 1.5rem;
  margin-top: 1.3rem;
  flex-wrap: wrap;
  /* overflow-x: scroll; */
  padding-left: 2rem;
  max-width: 1380px;

  ::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 465px) {
    min-width: auto;
    margin-left: auto;
    padding: 1rem;
  }
`;
export const TitleRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.5rem;
  border-bottom: ${(props) =>
    props.themeColor
      ? `1px solid ${colorProvider.blackTwo}`
      : commonStyles.com_b};
  padding-bottom: 1rem;

  @media (max-width: 769px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;
export const TitleBox = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;

  img {
    height: 13px;
    width: 13px;
    margin-right: 1rem;
    object-fit: contain;
    cursor: pointer;
  }
`;
export const HeadTitle = styled.div`
  font-size: 1.6rem;
  color: ${(props) => (props.themeColor ? "#F5F5F6" : "#24262d")};
  font-weight: 500;
  font-family: "EB Garamond", serif;
  text-transform: capitalize;
`;
export const HeadDesc = styled.div`
  font-size: 0.7rem;
  color: ${(props) => (props.themeColor ? "#94969C" : "#667085")};
  font-weight: 400;
  margin-top: 3px;
  text-transform: capitalize;
`;
export const CourseSub = styled.div`
  font-size: ${fontProvider.zeroSev};
  font-weight: ${fontWeight.five};
  color: ${(props) =>
    props.themeColor ? colorProvider.greyThree : colorProvider.comLink};
  text-transform: capitalize;
  line-height: 17px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const TrailerButton = styled.button`
  border: none;
  outline: none;
  cursor: pointer;
  background-color: ${colorProvider.orange};
  font-size: ${fontProvider.zeroEig};
  font-weight: ${fontWeight.six};
  color: ${colorProvider.white};
  border-radius: ${commonStyles.sec_br};
  text-align: ${commonStyles.jc_c};
  padding: ${fontProvider.zeroFive} ${fontProvider.zeroTen};
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.jc_c};
  align-items: ${commonStyles.jc_c};
  gap: 0.5rem;
  padding: 0.6rem 1rem;
  margin-top: 9rem;
  margin-right: 1rem;

  img {
    height: 20px !important;
    width: 20px !important;
    object-fit: contain;
  }
  /* @media (max-width: 320px) {
    width: 100%;
  } */
  @media (max-width: 480px) {
    // width: 100%;
  }
  @media (max-width: 769px) {
    // width: 100%;
  }
`;
export const ComContentRowTwo = styled.div`
  display: ${commonStyles.ds_fx};
  flex-direction: ${commonStyles.fd_col};
  cursor: ${commonStyles.pointer};
  align-items: ${commonStyles.jc_sb};
  width: 100%;
`;
export const VideoContainerSo = styled.div`
  width: 100%;
  min-width: 700px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  background-color: ${(props) =>
    props.themeColor ? colorProvider.blackFive : colorProvider.white};
  padding: ${(props) => (props.isFullScreen ? "0" : "1.2rem")};
  /* padding: 1.2rem; */
  border-radius: 8px;
  position: relative;
  gap: 20px;

  @media (max-width: 600px) {
    min-width: auto;
  }
`;

export const HlsVideoContainerSo = styled.div`
  width: 100%;
  min-width: 700px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  background-color: ${(props) =>
    props.themeColor ? colorProvider.blackFive : colorProvider.white};
  padding: 1.5rem;
  div {
    max-height: 450px;
  }

  @media (max-width: 1024px) {
    min-width: 600px;
  }

  @media (max-width: 780px) {
    min-width: 400px;
    padding: 1rem;
  }

  @media (max-width: 475px) {
    min-width: 300px;
    padding: 1rem;
  }

  @media (max-width: 475px) {
    min-width: 250px;
    padding: 1rem;
  }
`;

export const BannerPlay = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  position: relative;

  img {
    height: 450px;
    width: 800px;
    object-fit: contain;
  }

  .play-butttonon {
    position: absolute;
    /* top: 50%; */
    height: 65px;
    width: 65px;
    object-fit: contain;
    /* margin-top: 1.5rem; */
    /* margin-left: -4rem; */
    cursor: pointer;
  }
`;

export const NoDataCon = styled.div`
  width: 100%;
  min-height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    font-size: 2rem;

    color: ${(props) =>
      props.themeColor ? colorProvider.greyThree : colorProvider.comLink};
    @media (max-width: 768px) {
      font-size: 1rem;
    }
  }
`;
export const SuccessIconBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;

  img {
    height: 60px;
    width: 60px;
    object-fit: contain;
  }
`;
export const Title = styled.div`
  width: 100%;
  font-size: 1.2rem;
  color: ${(props) => (props.themeColor ? "#F5F5F6" : "#24262D")};
  font-weight: 600;
  margin-bottom: 1rem;
  text-align: center;
`;
export const Subtitle = styled.div`
  width: 100%;
  font-size: 1rem;
  color: ${(props) => (props.themeColor ? "#CECFD2" : "#667085")};
  font-weight: 400;
  line-height: 25px;
  text-align: center;
  text-transform: capitalize;
`;
export const GreenContinue = styled.div`
  color: #ffffff;
  text-transform: capitalize;
  font-size: 0.9rem;
  font-weight: 600;
  background-color: #17b26a;
  border-radius: 8px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 0.85rem 1rem 0.85rem 1.3rem;
  gap: 0.5rem;
  margin: 2rem 0 1rem 0;
  cursor: pointer;

  @media (max-width: 769px) {
    margin-top: 1rem;
  }
`;


export const ParentTableThree = styled.div`
  width: 100%;
  /* min-height: 700px; */
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  border-radius: 8px;
  border: ${(props) =>
    props.themeColor ? "1px solid #333741" : "1px solid #edeef1"};
  @media (max-width: 769px) {
    margin-bottom: 6rem;
  }
`;

export const TableBox = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  /* margin-top: 1rem; */
  text-transform: capitalize;

  ::-webkit-scrollbar {
    width: 6px !important;
    /* Adjust the width as needed */
    /* background-color: black; */
  }

  /* Set the styles for the scrollbar thumb */
  ::-webkit-scrollbar-thumb {
    background-color: #888 !important;
    /* Color of the thumb */
    border-radius: 5px !important;
    /* Rounded corners for the thumb */
  }
  .ant-table-thead .ant-checkbox .ant-checkbox-inner {
    height: 21px !important;
    width: 21px !important;

    /* background: ${(props) =>
      props.themeColor
        ? `url(${darkCheckboxBaseIcon}) no-repeat center center !important`
        : `url(${checkboxBaseIcon}) no-repeat center center !important`}; */
    background-size: contain !important;
    /* background-color: transparent !important; */
    background-color: ${(props) =>
      props.themeColor ? "#0C111D" : "#FFFFFF"} !important;
    border: ${(props) =>
      props.themeColor ? "1px solid #333741" : "1px solid #B3B9C6"} !important;
    border-radius: 6px;
  }
  td.ant-table-cell.ant-table-cell-scrollbar {
    background-color: ${(props) =>
      props.themeColor ? "#1F242F" : "#FFFFFF"} !important;
    box-shadow: none !important;
  }
  .ant-table-thead .ant-checkbox .ant-checkbox-inner:after {
    height: ${(props) => (props.checkCon ? "" : "21px !important")};
    width: ${(props) => (props.checkCon ? "" : "21px !important")};
    background-color: transparent !important;
    background: ${(props) =>
      props.checkCon
        ? ""
        : props.themeColor
        ? `url(${checkbox_minus_dark}) no-repeat center center !important`
        : `url(${checkbox_minus_light}) no-repeat center center !important`};
    background-size: contain !important;
    border-color: transparent !important;
  }
  .ant-checkbox-checked .ant-checkbox-inner:after {
    height: ${(props) => (props.checkCon ? "22px !important" : "")};
    width: ${(props) => (props.checkCon ? "22px !important" : "")};
    margin-left: 6.2px !important;
    margin-top: 0.8px !important;
    transform: rotate(0deg) scale(1) translate(-50%, -50%) !important;
    background: ${(props) =>
      props.checkCon
        ? props.themeColor
          ? `url(${darkcheckIcon}) no-repeat center center !important`
          : `url(${checkboxIcon}) no-repeat center center !important`
        : ""};
  }

  &&&
    :where(.css-dev-only-do-not-override-p7e5j5).ant-table-wrapper
    .ant-table-pagination-right {
    background-color: ${(props) =>
      props.themeColor ? "#0C111D" : "#FFFFFF"} !important;
    border-top: ${(props) =>
      props.themeColor ? "1px solid #1F242F" : "1px solid #D7DAE0"};
    border-radius: 0 0 8px 8px !important;
  }
  &&& :where(.css-p7e5j5).ant-table-wrapper .ant-table-pagination-right {
    background-color: ${(props) =>
      props.themeColor ? "#0C111D" : "#FFFFFF"} !important;
    border-top: ${(props) =>
      props.themeColor ? "1px solid #1F242F" : "1px solid #D7DAE0"};
    border-radius: 0 0 8px 8px !important;
  }

  .border-border:where(.css-dev-only-do-not-override-p7e5j5).ant-table-wrapper
    .ant-table-container
    table
    > thead
    > tr:first-child
    > *:last-child {
    border-start-end-radius: 0px !important;
  }

  /* update */
  &&&
    :where(.css-dev-only-do-not-override-p7e5j5).ant-checkbox
    .ant-checkbox-inner {
    border-color: ${(props) =>
      props.checkCon
        ? "transparent !important"
        : props.themeColor
        ? "#333741 !important"
        : "#b3b9c6 !important"};
    border-width: 1px !important;
    background-color: ${(props) =>
      props.checkCon
        ? "transparent !important"
        : props.themeColor
        ? "#0C111D !important"
        : "#fff !important"};
    border-radius: 6px;
    height: 21px !important;
    width: 21px !important;
  }
  &&&
    :where(.css-dev-only-do-not-override-p7e5j5).ant-checkbox-wrapper:not(
      .ant-checkbox-wrapper-disabled
    ):hover
    .ant-checkbox-checked:not(.ant-checkbox-disabled)
    .ant-checkbox-inner {
    background-color: transparent !important;
    border-color: none !important;
  }
  /* @media (max-width: 769px) {
    display: none;
  } */
  &&& :where(.css-p7e5j5).ant-checkbox .ant-checkbox-inner {
    border-color: ${(props) =>
      props.checkCon
        ? "transparent !important"
        : props.themeColor
        ? "#333741 !important"
        : "#b3b9c6 !important"};
    border-width: 1px !important;
    background-color: ${(props) =>
      props.checkCon
        ? "transparent !important"
        : props.themeColor
        ? "#0C111D !important"
        : "#fff !important"};
    border-radius: 6px;
    height: 21px !important;
    width: 21px !important;
  }
  &&&
    :where(.css-p7e5j5).ant-checkbox-wrapper:not(
      .ant-checkbox-wrapper-disabled
    ):hover
    .ant-checkbox-checked:not(.ant-checkbox-disabled)
    .ant-checkbox-inner {
    background-color: transparent !important;
    border-color: none !important;
  }

  ::-webkit-scrollbar {
    width: 6px;
    /* Adjust the width as needed */
    /* background-color: black; */
  }

  /* Set the styles for the scrollbar thumb */
  ::-webkit-scrollbar-thumb {
    background-color: #888;
    /* Color of the thumb */
    border-radius: 5px;
    /* Rounded corners for the thumb */
  }
  /* @media (max-width: 769px) {
    display: none;
  } */
`;


export const PagiButton = styled.div`
  min-height: 2rem;
  border: 1px solid #d7dae0;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  position: absolute;
  left: 0;
  margin-left: 1rem;
  border: ${(props) =>
    props.themeColor ? "1px solid #334155" : "1px solid #D7DAE0"};
  background-color: ${(props) =>
    props.themeColor ? "#161B26" : "transparent"};
  opacity: ${(props) => (props.disabled ? "0.5" : "1")};

  p {
    font-size: 0.8rem;
    color: ${(props) => (props.themeColor ? "#94969C" : "#667085")};
    font-weight: 500;
    @media (max-width: 768px) {
      display: none;
    }
  }
  img {
    height: 12px;
    width: 12px;
    object-fit: contain;
  }
`;
export const PagiButtonNext = styled.div`
  min-height: 2rem;
  border: 1px solid #d7dae0;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  position: absolute;
  right: 0;
  margin-right: 1rem;
  border: ${(props) =>
    props.themeColor ? "1px solid #334155" : "1px solid #D7DAE0"};
  background-color: ${(props) =>
    props.themeColor ? "#161B26" : "transparent"};
  opacity: ${(props) => (props.disabled ? "0.5" : "1")};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};

  p {
    font-size: 0.8rem;
    color: ${(props) => (props.themeColor ? "#94969C" : "#667085")};
    font-weight: 500;
    @media (max-width: 768px) {
      display: none;
    }
  }
  img {
    height: 14px;
    width: 14px;
    object-fit: contain;
  }
`;  


export const InnerSectionsBanner = styled.div`
  width: 100%;
  min-width: 600px;
  text-align: left;
  @media (max-width: 768px) {
    min-width: unset;
  }
`;

export const SuccessIconBoxTwo = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0 0.8rem 0;

  img {
    height: 68px;
    width: 68px;
    object-fit: contain;
  }
`;

export const TitleTwo = styled.div`
  width: ${commonStyles.wid};
  /* font-family: EB Garamond; */
  font-size: 1.3rem;
  font-weight: ${fontWeight.five};
  color: ${colorProvider.blackOne};
  color: ${(props) =>
    props.themeColor ? colorProvider.navBackground : colorProvider.blackOne};
  text-align: ${commonStyles.jc_c};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 1rem;
`;