import React from "react";
import * as MS from "../../styles/ModelStyled";
import { useDispatch } from "react-redux";
import { setIsModal } from "../../store/action";
import redCross from "../../assets/images/greyCross.png";

const ModalTwo = ({
  component,
  isOpen,
  isClose,
  backColor,
  minWidth,
  maxWidth,
  title,
  theme,
  marginTopp
}) => {
  const dispatch = useDispatch();
  return isOpen ? (
    <MS.ModalContainer
    >
      <MS.InnerBoxTwo
      style={{ marginTop: marginTopp ? "-9rem" : "0" }}
        minWidth={minWidth}
        maxWidth={maxWidth}
        backColor={backColor}
        themeColor={theme}
      >
        {" "}
        {isClose ? (
          <MS.CrossRow
          themeColor={theme}
          title={title ? true : false}
            onClick={() => {
              isClose(false);
              dispatch(setIsModal(false));
            }}
          >
            <p>{title}</p>
            <img src={redCross} alt="" />
          </MS.CrossRow>
        ) : (
          ""
        )}
        {component}
      </MS.InnerBoxTwo>
    </MS.ModalContainer>
  ) : (
    ""
  );
};

export default ModalTwo;
