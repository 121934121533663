import styled from "styled-components/macro";

export const ServicesContainer = styled.div`
  height: auto;
  width: 100%;
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
  position: relative;
  /* background: red; */
  margin: 4rem 0;
`;

export const InnerContainer = styled.div`
  width: 100%;
  display: flex;
  /* max-width: 1366px; */
  height: 210px;
  justify-content: center;
  align-items: center;
  /* position: absolute; */
  background-color: aqua;
  /* margin: -8rem 0; */

  /* @media(max-width: 566px){
        height: 600px;
    }
    @media(max-width: 426px){
        height: 380px;
    } */
  @media (max-width: 890px) {
    height: 165px;
  }
  @media (max-width: 521px) {
    height: 125px;
  }
  @media (max-width: 397px) {
    height: 100px;
  }
`;

export const CounterWrap = styled.div`
  width: 100%;
  max-width: 1300px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CounterContent = styled.div`
  width: 100%;
  max-width: 1250px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1035px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 2rem;
  }
`;

export const CountBox = styled.div`
  height: 100%;
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* background-color: black; */
  background: #dddddd1a;
  padding: 1.5rem 0.1rem;
  border-radius: 12px;
  gap: 0.5rem;

  @media (max-width: 1035px) {
   width: 100%;
  }
`;

export const CountNo = styled.div`
  color: #ffffff;
  font-size: 1.5rem;
  font-weight: 500;
  @media (max-width: 890px) {
    font-size: 2.5rem;
  }
  @media (max-width: 602px) {
    font-size: 2rem;
  }
  @media (max-width: 445px) {
    font-size: 1.5rem;
  }
`;

export const CountText = styled.div`
  color: #eb7d35;
  font-size: 1rem;
  font-weight: 700;
  text-align: center;
  @media (max-width: 890px) {
    font-size: 1.1rem;
  }
  @media (max-width: 602px) {
    font-size: 0.8rem;
  }
`;
