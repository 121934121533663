import CryptoJS from "crypto-js";
import moment from "moment";
const secretKeyMain = "rmLearnerSecretKey";
export const decryptData = (encryptedData) => {
  if (!encryptedData) {
    return null; // Return null if no encrypted data is available
  }

  try {
    // Decrypt data using AES and secret key
    const bytes = CryptoJS.AES.decrypt(encryptedData, secretKeyMain);
    const decryptedData = bytes?.toString(CryptoJS.enc.Utf8);

    // If the result is empty, return null to prevent JSON parsing errors
    if (!decryptedData) {
      return null;
    }

    return JSON.parse(decryptedData);
  } catch (error) {
    console.error("Decryption error:", error);
    return null; // Return null if there's a decryption error
  }
};
export function formatFileSize(bytes) {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  const formattedSize = parseFloat((bytes / Math.pow(k, i)).toFixed(2));

  return `${formattedSize} ${sizes[i]}`;
}

const secretKey = "yourSecretKey";
export const encryptedData = (dataToEncrypt) => {
  return CryptoJS.AES.encrypt(dataToEncrypt, secretKey).toString();
};
export const decryptedData = (dataToEncrypt) => {
  var bytes = CryptoJS.AES.decrypt(dataToEncrypt, secretKey);
  var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  return decryptedData;
};

export function removeDuplicatesById(arr) {
  const seenIds = new Set();
  return arr.filter((obj) => {
    if (seenIds.has(obj._id)) {
      return false; // Duplicate _id, filter it out
    }
    seenIds.add(obj._id);
    return true; // Unique _id, keep it
  });
}

export function combineDateAndTime(dateInput, timeInput) {
  // const date = new Date(dateInput);
  // const [hours, minutes] = timeInput?.split(":")?.map(Number);

  // // Assuming the input date is in local time, adjust it to UTC based on local offset
  // // This is crucial if the input time is meant to be treated as local time
  // const localOffset = date?.getTimezoneOffset() * 60000; // Offset in milliseconds
  // const adjustedDate = new Date(date?.getTime() + localOffset); // Adjust to UTC

  // adjustedDate?.setHours(hours, minutes, 0, 0); // Set time, assuming the timeInput is in local time

  // // Convert to ISO string
  // return adjustedDate.toISOString();
  const [hours, minutes] = timeInput?.split(":")?.map(Number);

  dateInput.setHours(hours, minutes, 0, 0);

  const pad = (num) => num?.toString()?.padStart(2, "0");

  const localFormattedDate = `${dateInput?.getFullYear()}-${pad(
    dateInput?.getMonth() + 1
  )}-${pad(dateInput?.getDate())}T${pad(dateInput?.getHours())}:${pad(
    dateInput?.getMinutes()
  )}`;

  return localFormattedDate;
}

export function convertTimeFormat(timeStr, lang) {
  let [hh, mm, ss] = timeStr?.split(":");
  // return `${parseInt(hh)}h ${parseInt(mm)}m ${parseInt(ss)}s`;
  return `${parseInt(mm)} ${lang} ${parseInt(ss)} Sec`;
}
export const formatTime = (time) => {
  //formarting duration of video
  if (isNaN(time)) {
    return "00:00";
  }

  const date = new Date(time * 1000);
  const hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();
  const seconds = date.getUTCSeconds().toString().padStart(2, "0");
  if (hours) {
    //if video have hours
    return `${hours}:${minutes.toString().padStart(2, "0")} `;
  } else return `${minutes}:${seconds}`;
};
export function convertSecondsToTimeString(seconds) {
  const totalSeconds = Math.floor(seconds); // Extract integer part of total seconds
  const hours = Math.floor(totalSeconds / 3600); // Extract hours
  const remainingSecondsAfterHours = totalSeconds % 3600;
  const minutes = Math.floor(remainingSecondsAfterHours / 60); // Extract minutes
  const secs = remainingSecondsAfterHours % 60; // Extract seconds

  // Pad single digit hours, minutes, and seconds with leading zeros
  const paddedHours = String(hours).padStart(2, "0");
  const paddedMinutes = String(minutes).padStart(2, "0");
  const paddedSeconds = String(secs).padStart(2, "0");

  return `${paddedHours}:${paddedMinutes}:${paddedSeconds}`;
}
export function timeStringToSeconds(timeString) {
  if (timeString) {
    const [hours, minutes, seconds] = timeString?.split(":").map(Number);

    return hours * 3600 + minutes * 60 + seconds;
  }
}

export function timeDifference(totalTime, lastTime) {
  // Function to convert HH:MM:SS into total seconds
  function convertToSeconds(time) {
    const [hours, minutes, seconds] = time?.split(":")?.map(Number);
    return hours * 3600 + minutes * 60 + seconds;
  }

  // Convert both times to seconds
  const totalSeconds = convertToSeconds(totalTime);
  const lastSeconds = convertToSeconds(lastTime);

  // Calculate the difference in seconds
  let remainingSeconds = totalSeconds - lastSeconds;

  // Convert seconds back to HH:MM:SS
  const hours = Math.floor(remainingSeconds / 3600);
  remainingSeconds %= 3600;
  const minutes = Math.floor(remainingSeconds / 60);
  const seconds = remainingSeconds % 60;

  let formattedTime = "";
  if (hours > 0) {
    formattedTime += `${hours}h `;
  }
  if (minutes > 0 || hours > 0) {
    formattedTime += `${minutes}m`;
  }

  return formattedTime.trim();
}

export function percentageTimeRemaining(totalTime, lastTime) {
  // Function to convert HH:MM:SS into total seconds
  function convertToSeconds(time) {
    const [hours, minutes, seconds] = time?.split(":")?.map(Number);
    return hours * 3600 + minutes * 60 + seconds;
  }

  // Convert both times to seconds
  const totalSeconds = convertToSeconds(totalTime);
  const lastSeconds = convertToSeconds(lastTime);

  // Calculate remaining time in seconds
  const remainingSeconds = totalSeconds - lastSeconds;

  // Calculate the percentage of time remaining
  const percentageRemaining = (remainingSeconds / totalSeconds) * 100;
  const percentageRemainingConvert = 100 - percentageRemaining;
  // console.log(percentageRemaining, "percentageRemaining percentageRemaining");

  return percentageRemainingConvert?.toFixed(2); // Format to two decimal places
}

export function getRemainingDays(targetDate) {
  // Convert the target date string to a Date object
  const givenDate = new Date(targetDate);

  // Get the current date
  const currentDate = new Date();

  // Calculate the difference in milliseconds
  const diffInMilliseconds = givenDate - currentDate;

  // Convert milliseconds to days
  const remainingDays = Math.ceil(diffInMilliseconds / (1000 * 60 * 60 * 24));

  return remainingDays;
}
function loadLocale(locale) {
  switch (locale) {
    case "kn":
      require("moment/locale/kn");
      break;
    case "hi":
      require("moment/locale/hi");
      break;
    case "ta":
      require("moment/locale/ta");
      break;
    case "te":
      require("moment/locale/te");
      break;
    default:
      console.log("Locale not supported");
  }
}
export function formatDate(date, locale) {
  loadLocale(locale); // Ensure the locale is loaded
  moment.locale(locale);
  return moment(date).format("DD MMMM YYYY");
}
export function convertToSeconds(time) {
  const parts = time?.split(":");
  if (parts.length === 2) {
    // parts[0] is minutes, parts[1] is seconds
    return parseInt(parts[0], 10) * 60 + parseInt(parts[1], 10);
  } else if (parts.length === 1) {
    // Only seconds are provided
    return parseInt(parts[0], 10);
  } else {
    // Handle unexpected format, perhaps throw an error or return 0
    console.error("Invalid time format");
    return 0;
  }
}
export const encryptData = (data) => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), secretKeyMain).toString();
};

export function timeAgo(date) {
  const seconds = Math.floor((new Date() - new Date(date)) / 1000);

  const intervals = {
      year: 31536000,
      month: 2592000,
      week: 604800,
      day: 86400,
      hour: 3600,
      minute: 60,
      second: 1
  };

  for (const key in intervals) {
      const interval = Math.floor(seconds / intervals[key]);
      if (interval >= 1) return `${interval} ${key}${interval > 1 ? 's' : ''} ago`;
  }
  return "Just now";
}