export const RMlogin = {
  login_url: "learner/login",
  otp_verification: "learner/otpVerification",
  forget_password: "learner/forgetPassword",
  reset_password: "learner/private/passwordChange",
  login_mobile: "learner/loginMobile",
  login_azure: "azure/learnerLogin",
  getBanner: "admin/banner/public/exitsBannerDetails",
  getClientLearner: "adminImpersonate/",
  getLanguage: "learner/private/languageInfo"
};
export const Users = {
  userList: "client/userList",
  userRegister: "client/register",
  editRegister: "client/userUpdate",
  detailUser: "client/userDetails/",
};
export const Course = {
  getMyCourses: "learner/private/subscriberCourse",
  getCourseContent: "client/private/my_course/details",
  getLearner: "client/private/course/learners",
  getFeedbackQues: "learner/private/course/feedback/details",
  getQuizList: "learner/private/course/Quiz/list",
  getQuizDetail: "learner/private/course/Quiz/details/",
  getCourseDetail: "learner/private/subscriberCourseDescription",
  getCategoryMightInterest: "learner/private/categorieMightInterest",
  getCourseEpisodeData: "learner/private/subscriberCourseEpisodes",
  quizSubmit: "learner/private/userQuizSubmit",
  getPlayEpisode: "learner/private/userPlayEpisodes",
  getQuizResult: "learner/private/course/Quiz/result/",
  feedbackSubmit: "learner/private/userFeedbackCreate",
  getCourseCompleted: "learner/private/completeCourse",
  getCourseContinue: "learner/private/continueLearning",
  restartEpisode: "learner/private/restart_episodes/",
  getCourseMenu: "learner/private/learnerCourseMenu",
  getLeaderBoard: "learner/private/leaderBoard",
};
export const Episode = {
  timeSubmit: "learner/private/user_episode_last_time_stamp",
  userEpisodeWatchInfo: "learner/private/user_episode_watch_info",
  interactiveQuizSubmit: "learner/private/user_episode_interactiveQuiz_subumit",
  startLearning: "learner/private/watchCurrentEpisode",
  restartCourse: "learner/private/restartCourse/",
  episodeComplete: "learner/private/episodeComplete",
  restartEpisode: "learner/private/restartEpisode",
};
export const Dashboard = {
  getDashboard: "learner/private/dashboardInfo",
};
export const Certificate = {
  getCertificate: "learner/private/genrateCerficate",
  getCertificateInfo: "learner/private/learnerCertificateInfo/",
  CertificateDownload: "learner/private/CertificateDownload",
};
export const Policy = {
  getPolicy: "learner/private/policyDocument/",
  documentStatus: "learner/private/policyDocumentStatus",
};
export const NextTab = {
  nextTab: "learner/private/policyDocumentNextTab/",
};
export const Commitee = {
  getCommitte: "learner/private/Committee",
};
export const Refresh = {
  refreshFuntion: "learner/private/userInfoRemove",
};
export const ProfileInfo = {
  getProfileDetails: "learner/private/profile/details",
  updateProfile: "learner/private/profile/update",
  changePassword: "learner/private/profile/changePassword",
};
export const GetNotification = {
  getNotification: "learner/private/notificationList",
  removeImpersonate: "learner/private/removeImpersonate"
}
export const Badges = {
  getBadges: "learner/private/badge/",
}
export const NavbarAP = {
  setLang: "learner/private/userSeletedlanguge/",
}
export const QuizList = {
  getQuiz: "admin/quiz/list"
}

export const RaisaTickto = {
  getTickets: "learner/private/support-ticket/list",
  createTicket: "learner/private/support-ticket/create",
  getIssueType: "learner/private/support-ticket/issueType",
}