import React, {
  useState,
  Suspense,
  useEffect,
  lazy,
  useRef,
  startTransition,
} from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import { publicRoutes, privateRoutes, courseRoutes } from "./routes";
import { useSelector, useDispatch } from "react-redux";
import * as CS from "./styles/CommonStyled";
import * as LE from "./styles/LoginStyled";
import * as SEC from "./styles/SectionStyledTwo";
import Navbar from "./components/Navbar";
import { RMlogin } from "./utils/api";
import bannerSample from "./assets/images/bannerSample.png";
import axios, { updateAccessToken } from "./utils/axios";
import Sidebar from "./components/Sidebar/Sidebar";
import backVideo from "./assets/images/backVideo.mp4";
// const Course = lazy(() => import("./pages/private/Courses/Course"));
import Courses from "./pages/private/Courses/CourseContent/Courses";
import LayoutPage from "./Layout";
import VideoComponent from "./VideoComponent";
import {
  setAccessToken,
  setDynamicRoutes,
  setErrorPop,
  setIsActionDisabled,
  setLanguages,
  setNewUser,
  setOnBoarding,
  setSelectLang,
  setSelectLangJson,
  setShowVideoo,
} from "./store/action";
import CryptoJS from "crypto-js";
import { encryptData } from "./utils/Helpers";
import soundIcon from "./assets/images/soundIcon.png";
import Modal from "./components/Modal/Modal";
import demoImage7 from "./assets/images/demoImage7.png";
import { colorProvider } from "./utils/StylePropertiy";
import * as AS from "./styles/ArrivalStyled";
import { jwtDecode } from "jwt-decode";
import { Rating } from "react-simple-star-rating";
import warn from "./assets/images/warns.png";
import ModalComplete from "./components/Modal/ModalComplete";
import * as QS from "./styles/QuizStyled";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import * as PA from "./CounterElements";
import OnBoarding from "./OnBoarding";
const secretKeyMain = "rmLearnerSecretKey";

const PublicRoute = () => {
  const videoRef = useRef(null);
  let navigate = useNavigate();
  let location = useLocation();
  const dispatch = useDispatch();
  const theme = useSelector((state) => state?.isDark);
  const isFormTypeVal = useSelector((state) => state?.isFormTypeVal);
  const [bannerData, setBannerData] = useState([]);
  const [bannerConfigData, setBannerConfigData] = useState([]);
  const [counterOn, setCounterOn] = useState(false);
  const getBanner = async () => {
    let url = RMlogin.getBanner;
    try {
      const { data } = await axios.get(url);
      setBannerData(data.data[0]);
      setBannerConfigData(data?.config);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getBanner();
  }, []);
  const pauseVideo = () => {
    if (videoRef.current) {
      videoRef.current.pause();
    }
  };

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.playbackRate = 0.5;
    }
  }, []);
  return (
    <Routes>
      {publicRoutes.map((val, ind) => {
        return (
          <Route
            exact
            key={ind}
            path={val.path}
            element={
              <Suspense>
                <CS.MainContainer>
                  <CS.InnerContainerTwo>
                    <CS.BackContainer>
                      <video
                        ref={videoRef}
                        className="video-background"
                        width="320"
                        height="240"
                        autoPlay
                        loop
                        muted
                      >
                        <source src={backVideo} type="video/mp4" />
                      </video>
                      <val.component
                        navigate={navigate}
                        location={location}
                        dispatch={dispatch}
                        handlePauseVideo={pauseVideo}
                      />
                      {/* {window.location.pathname === "/" ? ( */}
                      {isFormTypeVal?.isLogin ? (
                        <LE.BannerImageBox>
                          <ScrollTrigger
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                              marginTop: "2rem",
                            }}
                            onEnter={() => setCounterOn(true)}
                            onExit={() => setCounterOn(false)}
                          >
                            {/* <PA.CounterWrap> */}
                            <PA.CounterContent>
                              <PA.CountBox>
                                <PA.CountNo>
                                  {counterOn && (
                                    <CountUp
                                      start={0}
                                      end={
                                        bannerConfigData?.googleReviewsNumber
                                      }
                                      duration={5}
                                      delay={0}
                                    />
                                  )}
                                </PA.CountNo>
                                <PA.CountText>Google Review</PA.CountText>
                                {bannerConfigData?.googleReviews ? (
                                  <LE.ReviewBox>
                                    <LE.ReviewBoxOne>
                                      <div
                                        style={{
                                          display: "flex",
                                          gap: "5px",
                                          cursor: "pointer",
                                        }}
                                      >
                                        {[1, 2, 3, 4, 5].map((starIndex) => (
                                          <div
                                            key={starIndex}
                                            style={{
                                              position: "relative",
                                              display: "inline-block",
                                            }}
                                          >
                                            <div
                                              style={{
                                                position: "absolute",
                                                width: "50%",
                                                height: "100%",
                                                left: 0,
                                                zIndex: 1,
                                              }}
                                            ></div>

                                            <div
                                              style={{
                                                position: "absolute",
                                                width: "50%",
                                                height: "100%",
                                                right: 0,
                                                zIndex: 1,
                                              }}
                                            ></div>

                                            <i
                                              className={`fa fa-star${
                                                bannerConfigData?.googleReviewsRating >=
                                                starIndex
                                                  ? ""
                                                  : bannerConfigData?.googleReviewsRating >=
                                                    starIndex - 0.5
                                                  ? "-half-full"
                                                  : "-o"
                                              } fa-x`}
                                              style={{
                                                color:
                                                  bannerConfigData?.googleReviewsRating >=
                                                  starIndex - 0.5
                                                    ? "#f5c518"
                                                    : "#ccc",
                                              }}
                                            ></i>
                                          </div>
                                        ))}
                                      </div>
                                      <p>
                                        {bannerConfigData?.googleReviewsRating}
                                      </p>
                                    </LE.ReviewBoxOne>
                                  </LE.ReviewBox>
                                ) : (
                                  ""
                                )}
                              </PA.CountBox>
                              <PA.CountBox>
                                <PA.CountNo>
                                  {counterOn && (
                                    <CountUp
                                      start={0}
                                      end={265}
                                      duration={5}
                                      delay={0}
                                    />
                                  )}
                                </PA.CountNo>
                                <PA.CountText>
                                  Total Number of Clients
                                </PA.CountText>
                              </PA.CountBox>
                              <PA.CountBox>
                                <PA.CountNo>
                                  {counterOn && (
                                    <CountUp
                                      start={0}
                                      end={bannerConfigData?.totalLearnerCount}
                                      duration={5}
                                      delay={0}
                                    />
                                  )}
                                </PA.CountNo>
                                <PA.CountText>
                                  Total Number of Learners
                                </PA.CountText>
                              </PA.CountBox>
                            </PA.CounterContent>
                            {/* </PA.CounterWrap> */}
                          </ScrollTrigger>
                          <LE.BannerHead>
                            <LE.BannerTitle
                              style={{
                                width: bannerConfigData?.googleReviews
                                  ? "100%"
                                  : "100%",
                              }}
                            >
                              {bannerData?.showTextStatus
                                ? `${bannerData?.showText}`
                                : ""}
                            </LE.BannerTitle>
                          </LE.BannerHead>
                          {bannerData?.showBannerImageStatus ? (
                            <LE.BannerImage
                              href={bannerData?.bannerLink}
                              target="_blank"
                            >
                              <img src={bannerData?.bannerImage} alt="" />
                            </LE.BannerImage>
                          ) : (
                            ""
                          )}
                        </LE.BannerImageBox>
                      ) : (
                        ""
                      )}
                      {/* ) : (
                        ""
                      )} */}
                    </CS.BackContainer>
                  </CS.InnerContainerTwo>
                </CS.MainContainer>
              </Suspense>
            }
          />
        );
      })}
      <Route path="/*" element={<Navigate to="/learner" />} />
    </Routes>
  );
};
const PrivateRoute = ({ dynamicRoute }) => {
  let navigate = useNavigate();
  let location = useLocation();
  const dispatch = useDispatch();
  const theme = useSelector((state) => state?.isDark);
  const courseId = useSelector((state) => state?.courseId);
  const selectLang = useSelector((state) => state?.selectLang);
  const languages = useSelector((state) => state?.languages);
  const errorPop = useSelector((state) => state?.errorPop);
  const errorPopMsg = useSelector((state) => state?.errorPopMsg);
  const selectLangJson = useSelector((state) => state?.selectLangJson);
  const [localState, setLocalState] = useState({});
  useEffect(() => {
    if (selectLang) {
      let filterLangJson = languages?.find((val) => val._id === selectLang);
      dispatch(
        setSelectLangJson(JSON.parse(filterLangJson?.localization_json))
      );
      const modifiedLangJson = {
        ...JSON.parse(filterLangJson?.localization_json),
      };
      dispatch(setSelectLangJson(modifiedLangJson));
      console.log(JSON.parse(filterLangJson?.localization_json), "Lang Json");
    }
  }, [selectLang]);
  const handleClose = () => {
    dispatch(setErrorPop(false));
  };
  return (
    <>
      <Routes>
        <Route path="/" element={<LayoutPage dynamicRoute={dynamicRoute} />}>
          {privateRoutes.map((val, ind) => (
            <Route
              key={ind}
              path={`${dynamicRoute}${val.path}`}
              element={
                <Suspense fallback={<div>Loading...</div>}>
                  <val.component
                    navigate={navigate}
                    location={location}
                    dispatch={dispatch}
                    theme={theme}
                    courseId={courseId}
                    localState={localState}
                    dynamicRoute={dynamicRoute}
                  />
                </Suspense>
              }
            />
          ))}
        </Route>
        <Route path="/" element={<Courses dynamicRoute={dynamicRoute} />}>
          {courseRoutes.map((val, ind) => (
            <Route
              key={ind}
              path={`${dynamicRoute}${val.path}`}
              element={
                <Suspense fallback={<div>Loading...</div>}>
                  <val.component
                    navigate={navigate}
                    dispatch={dispatch}
                    location={location}
                    courseId={courseId}
                    theme={theme}
                    localState={localState}
                    dynamicRoute={dynamicRoute}
                  />
                </Suspense>
              }
            />
          ))}
        </Route>
        <Route
          path="/*"
          element={<Navigate to={`${dynamicRoute || ""}/learner/dashboard`} />}
        />
      </Routes>
      <ModalComplete
        isOpen={errorPop}
        maxWidth="400px"
        theme={theme}
        component={
          <SEC.InnerSectionSuccessSoEpCom
            themeColor={theme}
            style={{ padding: "1rem 0" }}
          >
            <SEC.SuccessIconBoxSo>
              <img src={theme ? warn : warn} alt="danger" />
            </SEC.SuccessIconBoxSo>
            <SEC.TitleSo themeColor={theme}>
              {selectLangJson?.warning}
            </SEC.TitleSo>
            <SEC.Subtitle themeColor={theme}>
              {errorPopMsg === "expired_course"
                ? selectLangJson?.course_expired
                : ""}
            </SEC.Subtitle>
            <QS.ButtonGroup style={{ width: "100%" }} themeColor={theme}>
              <QS.CancelButton
                style={{ width: "100%", padding: "1rem", fontSize: "1rem" }}
                themeColor={theme}
                onClick={() => {
                  // setIsPopPo(false);
                  dispatch(setErrorPop(false));
                }}
              >
                {selectLangJson?.cancel}
              </QS.CancelButton>
            </QS.ButtonGroup>
          </SEC.InnerSectionSuccessSoEpCom>
        }
      />
    </>
  );
};

const App = () => {
  // const state = useSelector((state) => state?.userData);
  const videoRef = useRef(null);
  const dispatch = useDispatch();
  const access_token = useSelector((state) => state?.access_token);
  const themeColor = useSelector((state) => state?.isDark);
  const selectLangJson = useSelector((state) => state?.selectLangJson);
  let location = useLocation();
  let navigate = useNavigate();
  const showVideo = useSelector((state) => state?.showVideo);
  const onBoarding = useSelector((state) => state?.onBoarding);

  console.log(showVideo, "showVideo vshowVideo");
  console.log(onBoarding, "onBoarding onBoarding");
  const [backVideo, setBackVideo] = useState(showVideo);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const dynamicRoutes = useSelector((state) =>
    state?.dynamicRoutes ? `/${state?.dynamicRoutes}` : ""
  );
  // const [isOpen, setIsOpen] = useState(onBoarding);
  const [isOpen, setIsOpen] = useState(onBoarding);
  const [dynamicRoute, setDynamicRoute] = useState("");
  console.log(isOpen, "isOpen");
  console.log(onBoarding, "onBoarding");
  const [onBoardingScreens, setOnBoardingScreens] = useState({
    firstScreen: true,
    secondScreen: false,
    thirdScreen: false,
    fourthScreen: false,
    fifthScreen: false,
    sixthScreen: false,
  });
  let firstPlay =
    "https://sachin-testing.s3.ap-south-1.amazonaws.com/new_episodes/1740036446967_879513982/720p/720p.m3u8";

  console.log(
    currentTime,
    "currentTimecurrentTimecurrentTimecurrentTimecurrentTime"
  );
  console.log(duration, "durationdurationdurationdurationduration");
  // useEffect(() => {
  //   if (currentTime?.toFixed() > 2) {
  //     console.log("second stage");
  //     if (currentTime?.toFixed() === duration) {
  //       console.log("sucoo");
  //       setBackVideo(false);
  //       dispatch(setShowVideoo(false));
  //     }
  //   }
  // }, [currentTime, duration]);

  useEffect(() => {
    if (Number(currentTime?.toFixed()) > 2) {
      console.log("second stage");

      if (Math.abs(Number(currentTime?.toFixed()) - duration) < 0.1) {
        console.log("success");
        setBackVideo(false);
        dispatch(setShowVideoo(false));
      }
    }
  }, [currentTime, duration]);

  console.log("tick updated 4:05 PM");
  const [accessTokens, setAccessTokens] = useState(
    new URLSearchParams(location.search).get("accessToken")
  );
  console.log(location.search, "location.search");

  const [timeSt, setTimeSt] = useState(
    new URLSearchParams(location.search).get("timestamp")
  );
  const [userId, setUserId] = useState(
    new URLSearchParams(location.search).get("userId")
  );
  const [otpCode, setOtpCode] = useState(
    new URLSearchParams(location.search).get("code")
  );
  const [screenJump, setScreenJump] = useState(
    new URLSearchParams(location.search).get("type")
  );
  const [data, setData] = useState(
    new URLSearchParams(location.search).get("data")
  );
  const [userType, setuserType] = useState(
    new URLSearchParams(location.search).get("userType")
  );
  const [newUser, setnewUser] = useState(
    new URLSearchParams(location.search).get("newUser")
  );
  console.log(newUser, "newUser newUsernewUser");
  const getUserList = async (e) => {
    try {
      const decodedUserType = userType?.replace(/['"]+/g, "");
      console.log(
        decodedUserType,
        "decodedUserType decodedUserTypedecodedUserType"
      );
      let url =
        decodedUserType === "client_admin"
          ? `${RMlogin.getClientLearner}${e}?userType=client_admin`
          : `${RMlogin.getClientLearner}${e}`;

      console.log(url, "url urlurlurlurlurl");
      const response = await axios.get(url);
      const { data } = response;
      console.log({ data }, "user list data");

      if (data?.data && data?.data?.HOME_SCREEN) {
        console.log(data, "inner one");
        const encryptedAccessToken = CryptoJS.AES.encrypt(
          data?.token?.accessToken,
          secretKeyMain
        ).toString();
        localStorage.setItem("rainLearnerToken", encryptedAccessToken);
        localStorage.setItem("languagesObj", encryptData(data?.data?.languges));
        const encryptedActionDisbaled = CryptoJS.AES.encrypt(
          data?.data?.ActionDisabled,
          secretKeyMain
        ).toString();
        localStorage.setItem("isActionDisabled", encryptedActionDisbaled);
        updateAccessToken(data?.token?.accessToken);
        dispatch(setAccessToken(data?.token?.accessToken));
        dispatch(setLanguages(data?.data?.languges));
        localStorage.setItem("rainLearnerVolume", 0.5);
        dispatch(setSelectLang(data?.data?.languges[0]?._id));
        dispatch(setIsActionDisabled(data?.data?.ActionDisabled));
        dispatch(setShowVideoo(true));
        dispatch(setOnBoarding(true));
        const encryptedSelectLang = CryptoJS.AES.encrypt(
          data?.data?.languges[0]?._id,
          secretKeyMain
        ).toString();
        window.localStorage.setItem("rainLearnerLang", encryptedSelectLang);
        // Navigate to dashboard
        navigate("/learner/dashboard");
      } else {
        console.log("HOME_SCREEN not found in data");
      }
    } catch (error) {
      console.error(
        "Error fetching user list: ",
        error.response ? error.response.data.message : error.message
      );
    }
  };

  useEffect(() => {
    const decodedScreenJump = screenJump?.replace(/['"]+/g, "");
    if (userId && decodedScreenJump === "impersonate") {
      getUserList(userId);
    }
  }, [userId, screenJump]);

  console.log(screenJump, "screenJump screenJump");

  // useEffect(() => {
  //   if (window.location.pathname?.includes("redirect")) {
  //     jumpFun();
  //   }
  // }, [accessTokens, screenJump, userId]);

  const getLanguage = async () => {
    const url = RMlogin.getLanguage;
    try {
      const { data } = await axios.get(url);

      console.log(data, "get lang lang");
      if (data?.data) {
        const encryptedSelectLang = CryptoJS.AES.encrypt(
          data?.data[0]?._id,
          secretKeyMain
        ).toString();
        window.localStorage.setItem("rainLearnerLang", encryptedSelectLang);
        localStorage.setItem("languagesObj", encryptData(data?.data));
        dispatch(setLanguages(data?.data));
        dispatch(setSelectLang(data?.data[0]?._id));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const jumpFun = () => {
    localStorage.clear();
    dispatch(setAccessToken(""));
    console.log("Step 1");
    console.log("Step 1", window.location.pathname);

    const decodedScreenJump = screenJump?.replace(/['"]+/g, ""); // Remove extra quotes
    console.log("Decoded screenJump:", decodedScreenJump);

    // const decodedNewUser = newUser?.replace(/['"]+/g, "");

    if (decodedScreenJump === "resetPassword") {
      updateAccessToken(accessTokens);
      dispatch(setNewUser(newUser));
      // localStorage.setItem("rainLearnerToken", accessTokens);
      console.log("Step 2");
      navigate("/learner/reset-password", {
        state: {
          userId: userId,
        },
      });
    } else if (decodedScreenJump === "impersonate") {
      console.log("Step 3");
      // Handle impersonation logic
    } else if (decodedScreenJump === "login") {
      console.log("step 4");
      console.log(accessTokens, "accessTokens accessTokens");
      const encryptedAccessToken = CryptoJS.AES.encrypt(
        accessTokens,
        secretKeyMain
      ).toString();
      localStorage.setItem("rainLearnerToken", encryptedAccessToken);
      const encryptedActionDisbaled = CryptoJS.AES.encrypt(
        data?.data?.ActionDisabled,
        secretKeyMain
      ).toString();
      localStorage.setItem("isActionDisabled", encryptedActionDisbaled);
      updateAccessToken(accessTokens);
      dispatch(setAccessToken(accessTokens));
      localStorage.setItem("rainLearnerVolume", 0.5);
      dispatch(setIsActionDisabled(data?.data?.ActionDisabled));
      dispatch(setShowVideoo(true));
      dispatch(setOnBoarding(true));
      getLanguage();
      // Navigate to dashboard
      navigate("/learner/dashboard");
    }

    console.log("Step 4");
  };

  useEffect(() => {
    if (onBoarding) {
      setIsOpen(true);
      setOnBoardingScreens({
        firstScreen: true,
        secondScreen: false,
        thirdScreen: false,
        fourthScreen: false,
        fifthScreen: false,
        sixthScreen: false,
      });
    }
  }, [onBoarding]);

  useEffect(() => {
    if (showVideo) {
      setBackVideo(true);
    }
  }, [showVideo]);
  return (
    <>
      <CS.ParentBox>
        {window.location.pathname?.includes("redirect") ? (
          jumpFun()
        ) : access_token ? (
          <CS.OverLapCon>
            {backVideo ? (
              <VideoComponent
                handleDuration={setDuration}
                handleCurrentTime={setCurrentTime}
                firstPlay={firstPlay}
              />
            ) : (
              ""
            )}

            <PrivateRoute dynamicRoute={dynamicRoutes} />
          </CS.OverLapCon>
        ) : (
          <PublicRoute />
        )}
      </CS.ParentBox>
      <Modal
        maxWidth={"700px"}
        isOpen={isOpen}
        backColor={colorProvider.darkBlue}
        theme={themeColor}
        component={
          <AS.ArrContainer>
            <OnBoarding
              selectLangJson={selectLangJson}
              handleIsOpen={setIsOpen}
              dispatch={dispatch}
              theme={true}
            />
          </AS.ArrContainer>
        }
      />
    </>
    // <CS.ParentBox><PrivateRoute /></CS.ParentBox>
  );
};

export default App;
