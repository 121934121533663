import styled from "styled-components/macro";
import {
  commonStyles,
  colorProvider,
  fontProvider,
  fontWeight,
} from "../utils/StylePropertiy";
import { Link } from "react-router-dom";

export const CourseContainer = styled.div`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  flex-direction: ${commonStyles.fd_col};
  justify-content: ${commonStyles.jc_fs};
  align-items: ${commonStyles.jc_fs};
  text-transform: ${commonStyles.cap};
`;
export const CourseHeader = styled.div`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.jc_c};
  align-items: ${commonStyles.jc_c};
  flex-direction: ${commonStyles.fd_col};
  background-color: ${(props) =>
    props.themeColor
      ? colorProvider.blackThree
      : colorProvider.navBackground};
  padding: ${fontProvider.zeroSix} 0;
  border-top: ${(props) =>
    props.themeColor ? `1px solid ${colorProvider.blackFive}` : `none`};
  border-bottom: ${(props) =>
    props.themeColor ? `1px solid ${colorProvider.blackFive}` : `none`};
`;
export const CourseInnerHeader = styled.div`
  width: ${commonStyles.wid};
  max-width: 1350px;
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.jc_sb};
  align-items: ${commonStyles.jc_c};
  /* background-color: ${(props) =>
    props.themeColor ? colorProvider.darkBlue : colorProvider.white}; */
  @media (max-width: 780px) {
    flex-direction: column;
    justify-content: flex-start;
    gap: 1rem;
  }
  @media (max-width: 1400px) {
    padding: 0rem 1rem;
  }
`;
export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
  @media (max-width: 780px) {
    width: 100%;
  }
`;
export const FirstHeader = styled.div`
  display: ${commonStyles.ds_fx};
  flex-direction: ${commonStyles.fd_col};
  justify-content: ${commonStyles.jc_c};
  align-items: ${commonStyles.jc_fs};

  .title {
    font-size: ${fontProvider.oneThree};
    font-weight: ${fontWeight.six};
    font-family: EB Garamond;
    color: ${(props) =>
      props.themeColor ? colorProvider.white : colorProvider.blackCol};

    @media (max-width: 769px) {
      font-size: 1rem;
    }

    @media (max-width: 480px) {
      font-size: 14px;
    }

    @media (max-width: 320px) {
      font-size: 0.8rem;
    }
  }

  .desc {
    font-size: ${fontProvider.zeroSev};
    font-weight: ${fontWeight.five};
    color: ${(props) =>
      props.themeColor ? colorProvider.whiteThree : colorProvider.comLink};

    @media (max-width: 767px) {
      font-size: 11px;
    }
  }
`;
export const CourseBody = styled.section`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.jc_c};
  align-items: ${commonStyles.jc_c};
  margin-top: ${fontProvider.oneEig};
  padding: 0rem 1rem 2rem 1rem;
  z-index: 0;
  #RespCont {
    @media (max-width: 780px) {
      display: none;
    }
  }
`;
export const CourseInnerBody = styled.section`
  width: ${commonStyles.wid};
  max-width: 1350px;
  display: ${commonStyles.ds_fx};
  flex-direction: ${commonStyles.fd_col};
  justify-content: ${commonStyles.jc_c};
  align-items: ${commonStyles.jc_c};
  position: relative;
`;
export const BannerContainer = styled.div`
  position: relative;
  height: 600px;
  width: ${commonStyles.wid};
  @media (max-width: 769px) {
    height: 430px;
  }
`;
export const BannerContainerMedia = styled.div`
  position: relative;
  height: 600px;
  width: ${commonStyles.wid};
  @media (max-width: 769px) {
    height: ${(props) => (props.videoPlay ? "600px" : "430px")};
  }
  @media (max-width: 551px) {
    height: ${(props) => (props.videoPlay ? "500px" : "430px")};
  }
  @media (max-width: 451px) {
    height: ${(props) => (props.videoPlay ? "400px" : "430px")};
  }
`;
export const LottieContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  position: absolute;
`;
export const BackImage = styled.div`
  width: ${commonStyles.wid};
  position: absolute;
  border-radius: 12px;
  top: 0;
  /* z-index: 1; */

  img {
    height: 700px;
    width: ${commonStyles.wid};
    border-radius: 12px;
    object-fit: cover;
    border: ${(props) =>
      props.themeColor ? `1px solid transparent;` : "1px solid #d7dae0;"};
  }

  .transBack {
    width: ${commonStyles.wid};
    height: 700px;
    display: ${commonStyles.ds_fx};
    flex-direction: ${commonStyles.fd_col};
    align-items: ${commonStyles.jc_c};
    justify-content: ${commonStyles.jc_fe};
    position: absolute;
    top: 0;
    background: linear-gradient(
      360deg,
      #0c111d 0%,
      rgba(12, 17, 29, 0.96) 11.96%,
      rgba(12, 17, 29, 0.72) 29.53%,
      rgba(12, 17, 29, 0.3734) 40.5%,
      rgba(12, 17, 29, 0) 52.02%
    );
    padding-bottom: 5rem;
    border-radius: 12px;
  }
  @media (max-width: 769px) {
    img,
    .transBack {
      height: 450px;
      padding-bottom: 0rem;
      object-fit: cover;
    }
    .transBack {
      padding-bottom: 1.5rem;
    }
  }

  .video-background-auto {
    object-fit: cover;
    border-radius: 12px;
  }
  .media-class-box > div > div > div .title {
    color: #fff;
  }
`;

export const BannerDesc = styled.div`
  width: 58%;
  @media (max-width: 600px) {
    width: 85%;
  }
  p {
    color: ${colorProvider.whiteFour};
    font-size: ${fontProvider.zeroEig};
    font-weight: ${fontWeight.four};
    line-height: 20px;
    /* text-align: ${commonStyles.jc_c}; */
    margin-top: ${fontProvider.zeroThree};
    margin-bottom: ${fontProvider.oneThree};
    text-transform: none;

    @media (max-width: 780px) {
      display: -webkit-box;
      -webkit-line-clamp: 2; /* Limits to 2 lines */
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
    }
    @media (max-width: 425px) {
      font-size: 12px;
    }
  }
`;
export const CourseContentBox = styled.section`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.jc_sb};
  align-items: ${commonStyles.jc_fs};
  margin-top: 8rem;
  gap: 1.5rem;
  @media (max-width: 769px) {
    flex-direction: column;
    gap: 0rem;
    margin-top: ${(props) =>
      props.themeColor ? `8rem` : "3rem"};
  }
  @media (max-width: 500px) {
    margin-top: 3rem;
  }
`;
export const FirstCourseContent = styled.div`
  width: 70%;
  display: ${commonStyles.ds_fx};
  flex-direction: ${commonStyles.fd_col};
  justify-content: ${commonStyles.jc_c};
  align-items: ${commonStyles.jc_fs};
  @media (max-width: 780px) {
    width: 100%;
  }
`;
export const InnerFirst = styled.div`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.jc_fs};
  align-items: ${commonStyles.jc_c};
  background-color: ${(props) =>
    props.themeColor ? colorProvider.blackThree : colorProvider.navBackground};
  border: ${(props) =>
    props.themeColor
      ? `1px solid ${colorProvider.blackFive}`
      : commonStyles.com_b};
  border-radius: 12px;
  padding: 18px;
  gap: 1rem;
  margin-bottom: 1.5rem;
  overflow-x: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  /* @media (max-width: 780px) {
    min-width: 738px;
  } */
`;
export const VideoContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;

  .video-background-auto {
    /* height: 100%;
    width: 100%; */
    object-fit: cover;
  }
`;
export const InnerFirstTwo = styled.div`
  text-decoration: none;
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.jc_fs};
  align-items: ${commonStyles.jc_c};
  flex-direction: ${commonStyles.fd_col};
  background-color: ${(props) =>
    props.themeColor ? colorProvider.blackThree : colorProvider.navBackground};
  border: ${(props) =>
    props.themeColor
      ? `1px solid ${colorProvider.blackFive}`
      : commonStyles.com_b};
  border-radius: 12px;
  padding: 18px;
  gap: 1rem;
  margin-bottom: 1.4rem;
  @media (max-width: 780px) {
    padding: 12px 1rem;
  }

  @media print {
    display: none;
  }
`;
export const WatchButton = styled.div`
  text-decoration: none;
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.jc_c};
  align-items: ${commonStyles.jc_c};
  background-color: ${(props) =>
    props.activeColor ? colorProvider.navLink : colorProvider.trannsp};
  border: ${(props) =>
    props.activeColor
      ? "none"
      : props.themeColor
      ? `1px solid ${colorProvider.blackTwo}`
      : commonStyles.com_b};
  border-radius: ${commonStyles.sec_br};
  padding: ${fontProvider.zeroSix} ${fontProvider.zeroEig};
  gap: ${fontProvider.zeroFive};
  cursor: ${commonStyles.pointer};
  white-space: nowrap;
  p {
    color: ${(props) =>
      props.activeColor
        ? colorProvider.white
        : props.themeColor
        ? colorProvider.greyThree
        : colorProvider.numberT};
    font-size: ${fontProvider.zeroEig};
    font-weight: ${fontWeight.six};
  }

  @media (max-width: 1316px) {
    min-width: 150px;
  }
`;
export const SecondCourseContent = styled.div`
  width: 30%;
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.jc_sb};
  align-items: ${commonStyles.jc_c};
  @media (max-width: 780px) {
    width: 100%;
  }
`;
export const SecondInner = styled.div`
  width: ${commonStyles.wid};
  min-height: 400px;
  display: ${commonStyles.ds_fx};
  flex-direction: ${commonStyles.fd_col};
  justify-content: ${commonStyles.jc_fs};
  align-items: ${commonStyles.jc_d};
  background-color: ${(props) =>
    props.themeColor ? colorProvider.blackThree : colorProvider.navBackground};
  border: ${(props) =>
    props.themeColor
      ? `1px solid ${colorProvider.blackFive}`
      : commonStyles.com_b};
  border-radius: 12px;
  padding: ${fontProvider.oneFive};
  @media (max-width: 780px) {
    padding: ${fontProvider.zeroTen};
  }
`;
export const ComContainer = styled.div`
  display: ${commonStyles.ds_fx};
  flex-direction: ${commonStyles.fd_col};
  justify-content: ${commonStyles.jc_fs};
  align-items: ${commonStyles.jc_fs};
  margin-bottom: 1.3rem;
`;
export const CourseTitle = styled.div`
  display: ${commonStyles.ds_fx};
  align-items: ${commonStyles.jc_c};
  gap: 7px;
  p {
    color: ${(props) =>
      props.themeColor ? colorProvider.greyThree : colorProvider.comLink};
    font-size: ${fontProvider.zeroEig};
    font-weight: ${fontWeight.six};
  }
  img {
    height: 16px;
    width: 16px;
    object-fit: ${commonStyles.ob_con};
  }
`;
export const CourseDesc = styled.div`
  p {
    color: ${(props) =>
      props.themeColor ? colorProvider.whiteThree : colorProvider.greyDark};
    font-size: ${fontProvider.zeroEig};
    font-weight: ${fontWeight.five};
    margin-top: ${fontProvider.zeroFive};
    line-height: 20px;
  }
`;
export const ReadMoreText = styled.div`
  cursor: ${commonStyles.pointer};
  p {
    color: ${(props) =>
      props.themeColor ? colorProvider.greyFive : colorProvider.comLink};
    font-size: ${fontProvider.zeroEig};
    font-weight: ${fontWeight.five};
    border-bottom: ${(props) =>
      props.themeColor
        ? `1px solid ${colorProvider.greyFive}`
        : `1px solid ${colorProvider.greyFive}`};
    padding-bottom: 0.01rem;
    margin-top: 0.5rem;
    width: 66px;
  }
`;
export const EpisodeReadMoreText = styled.div`
  cursor: ${commonStyles.pointer};
  p {
    color: ${(props) =>
      props.themeColor ? colorProvider.greyFive : colorProvider.comLink};
    font-size: ${fontProvider.zeroEig};
    font-weight: ${fontWeight.five};
    border-bottom: ${(props) =>
      props.themeColor
        ? `1px solid ${colorProvider.greyFive}`
        : `1px solid ${colorProvider.greyFive}`};
    padding-bottom: 0.01rem;
    margin-top: 0.5rem;
  }
`;
export const CourseTitleRow = styled.div`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.jc_sb};
  align-items: ${commonStyles.jc_c};
  padding: 0.3rem;
  @media (max-width: 410px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }
`;
export const ComTitle = styled.div`
  /* width: 50%; */
  display: ${commonStyles.ds_fx};
  align-items: ${commonStyles.jc_c};
  gap: 6px;
  p {
    color: ${(props) =>
      props.themeColor ? colorProvider.white : colorProvider.blackCol};
    font-size: ${fontProvider.oneOne};
    font-weight: ${fontWeight.six};
    @media (max-width: 767px) {
      font-size: 14px;
    }
  }
  span {
    color: ${(props) =>
      props.themeColor ? colorProvider.greyThree : colorProvider.dateColor};
    font-size: ${fontProvider.zeroEig};
    font-weight: ${fontWeight.five};
  }
  img {
    height: 35px;
    width: 35px;
    cursor: ${commonStyles.pointer};
    object-fit: ${commonStyles.ob_con};
  }
  @media (max-width: 398px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;
export const ComTitleTwo = styled.div`
  display: ${commonStyles.ds_fx};
  align-items: ${commonStyles.jc_c};
  gap: 6px;
  p {
    color: ${(props) =>
      props.themeColor ? colorProvider.white : colorProvider.blackCol};
    font-size: ${fontProvider.oneOne};
    font-weight: ${fontWeight.six};
  }
  span {
    color: ${(props) =>
      props.themeColor ? colorProvider.whiteThree : colorProvider.dateColor};
    font-size: ${fontProvider.zeroEig};
    font-weight: ${fontWeight.five};
  }
  img {
    height: 35px;
    width: 35px;
    cursor: ${commonStyles.pointer};
    object-fit: ${commonStyles.ob_con};
  }
`;
export const LangButtonRow = styled.div`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  align-items: ${commonStyles.jc_c};
  flex-wrap: wrap;
  margin-top: 1rem;
  gap: 0.6rem;
`;
export const ComLangButton = styled.div`
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.jc_c};
  align-items: ${commonStyles.jc_c};
  background-color: ${(props) =>
    props.activeColor
      ? "#F05D24"
      : props.themeColor
      ? colorProvider.darkBlue
      : colorProvider.white};
  border: ${(props) =>
    props.themeColor
      ? `1px solid ${colorProvider.blackTwo}`
      : commonStyles.com_b};
  border-radius: ${commonStyles.sec_br};
  padding: 10px 18px;
  cursor: pointer;
  p {
    color: ${(props) =>
      props.activeColor
        ? "#FFF"
        : props.themeColor
        ? colorProvider.white
        : colorProvider.blackSev};
    font-size: ${fontProvider.zeroSev};
    font-weight: ${fontWeight.five};
  }
`;
// Episode Listing
export const EpisodeList = styled.ul`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  flex-direction: ${commonStyles.fd_col};
  align-items: ${commonStyles.jc_c};
  background-color: ${(props) =>
    props.themeColor ? colorProvider.darkBlue : colorProvider.white};
  border: ${(props) =>
    props.themeColor
      ? `1px solid ${colorProvider.blackFive}`
      : commonStyles.course_bor};
  border-radius: ${commonStyles.sec_br};
  padding: 2.2rem;
  gap: 1rem;
  @media (max-width: 780px) {
    padding: 1rem;
  }
`;
export const EpisodesCard = styled.li`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  align-items: ${commonStyles.jc_fs};
  border-bottom: ${(props) =>
    props.themeColor
      ? `1px solid ${colorProvider.blackTwo}`
      : commonStyles.com_b};
  margin-top: 2rem;
  padding-bottom: 2rem;
  &:nth-last-child(1) {
    padding-bottom: 0rem;
    border-bottom: 0px;
  }
  /* @media (max-width:768px) {
    padding:0;
  } */
`;
export const SerialNumber = styled.div`
  width: 20px;
  p {
    color: ${(props) =>
      props.themeColor
        ? colorProvider.greyThree
        : props.watchColor
        ? colorProvider.blackTwo
        : colorProvider.comLink};
    font-size: ${fontProvider.oneThree};
    font-weight: ${fontWeight.five};
    @media (max-width: 480px) {
      font-size: 1rem;
    }
  }
`;
export const EpisodeContent = styled.div`
  width: 100%;
  display: ${commonStyles.ds_fx};
  flex-direction: ${commonStyles.fd_col};
  align-items: ${commonStyles.jc_c};
`;
export const EpisodeFirst = styled.div`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  align-items: ${commonStyles.jc_fs};
  gap: 0.7rem;
  @media (max-width: 1149px) {
    gap: 0.9rem;
  }
  @media (max-width: 1086px) {
    gap: 1rem;
  }
  @media (max-width: 1034px) {
    gap: 1.2rem;
  }
  @media (max-width: 972px) {
    gap: 1.5rem;
  }
  @media (max-width: 889px) {
    gap: 1.8rem;
  }
  @media (max-width: 780px) {
    flex-direction: column;
  }
`;
export const EpisodeImageBox = styled.div`
  width: 20%;
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.jc_c};
  /* align-items: ${commonStyles.jc_c}; */
  gap: 10px;
  @media (max-width: 780px) {
    width: 100%;
    justify-content: left;
    flex-direction: row-reverse;
  }
`;
export const ImageBox = styled.div`
  /* flex: 1; */
  /* width: 100%; */
  /* overflow: hidden; */
  border-radius: 12px;

  img {
    height: 80px;
    width: 120px;
    object-fit: fill;
    border-radius: 12px;
    border: ${(props) =>
      props.themeColor ? `1px solid transparent;` : "1px solid #d7dae0;"};
  }

  .play-butttonon {
    position: absolute;
    /* top: 50%; */
    height: 25px;
    width: 25px;
    object-fit: contain;
    margin-top: -3rem;
    margin-left: 2.9rem;
    cursor: pointer;
  }
  .play-butttonon-buttons {
    position: absolute;
    /* top: 50%; */
    height: 25px;
    width: 25px;
    object-fit: contain;
    cursor: pointer;
  }

  .play-butttonon-button {
    position: absolute;
    /* top: 50%; */
    height: 25px;
    width: 25px;
    object-fit: contain;
    margin-top: 2rem;
    margin-left: -4.5rem;
    cursor: pointer;
  }
  .video-class-element {
    object-fit: cover;
    border-radius: 12px;
    width: 100%;
  }
`;

export const ImageBoxTrailer = styled.div`
  /* flex: 1; */
  img {
    height: 80px;
    width: 120px;
    object-fit: fill;
    border-radius: 8px;
    border: ${(props) =>
      props.themeColor ? `1px solid transparent;` : "1px solid #d7dae0;"};
  }

  .play-butttonon {
    position: absolute;
    /* top: 50%; */
    height: 25px;
    width: 25px;
    object-fit: contain;
    margin-top: 2rem;
    margin-left: -4.5rem;
    cursor: pointer;
  }

  .video-class-element {
    object-fit: cover;
    border-radius: 12px;
  }
`;
export const MediaPlayerCon = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
`;
export const EpisodeVideoContainer = styled.div`
  width: 100%;
  height: 100%;
`;
export const ProgressContainer = styled.div`
  position: absolute;
  height: 3px;
  width: 100%;
  bottom: 4px;
  /* margin-left: -0.7rem; */
  /* margin-top: -4.5rem !important; */
  background-color: white;
  border-radius: 12px;
`;
export const ProgressBar = styled.div`
  height: 3px;
  background-color: ${colorProvider.orange};
  /* border-radius: 12px; */
`;
export const EpisodeContentBox = styled.div`
  width: 80%;
  display: ${commonStyles.ds_fx};
  flex-direction: ${commonStyles.fd_col};
  align-items: ${commonStyles.jc_fs};
  @media (max-width: 780px) {
    width: 100%;
  }
`;
export const EpisodeTitleRow = styled.div`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.jc_sb};
  align-items: ${commonStyles.jc_c};
  @media (max-width: 780px) {
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1rem;
  }
`;
export const EpisodeTitle = styled.div`
  display: ${commonStyles.ds_fx};
  align-items: ${commonStyles.jc_c};
  p {
    color: ${(props) =>
      props.themeColor
        ? colorProvider.white
        : props.watchColor
        ? colorProvider.blackSix
        : colorProvider.blackCol};
    font-size: ${fontProvider.zeroTen};
    font-weight: ${fontWeight.six};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: none !important;
    @media (max-width: 973px) {
      font-size: 0.9rem;
    }
  }
  img {
    height: 20px;
    width: 20px;
    object-fit: ${commonStyles.ob_con};
    margin-left: 7px;
  }

  /* @media (max-width: 973px) {
    p {
      font-size: ;
    }
  } */
`;
export const EpisodeTime = styled.div`
  display: ${commonStyles.ds_fx};
  align-items: ${commonStyles.jc_c};
  background-color: ${(props) =>
    props.themeColor ? colorProvider.blackThree : colorProvider.navBackground};
  border: ${(props) =>
    props.themeColor
      ? `1px solid ${colorProvider.blackTwo}`
      : commonStyles.course_bor};
  border-radius: ${commonStyles.sec_br};
  padding: 7px;
  gap: 0.4rem;

  p {
    color: ${(props) =>
      props.themeColor ? colorProvider.greyThree : colorProvider.numberT};
    font-size: ${fontProvider.zeroEig};
    font-weight: ${fontWeight.five};
  }
  img {
    height: 13px;
    width: 13px;
    object-fit: ${commonStyles.ob_con};
  }
`;
export const EpisodeDesc = styled.div`
  p {
    color: ${(props) =>
      props.themeColor ? colorProvider.greyThree : colorProvider.greyThree};
    font-size: ${fontProvider.zeroEig};
    font-weight: ${fontWeight.five};
    margin-top: ${fontProvider.zeroFive};
    line-height: 20px;

    @media (max-width: 320px) {
      word-wrap: break-word;
    }
  }
`;
export const EpisodeSecond = styled.div`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  /* justify-content: ${commonStyles.jc_sb}; */
  align-items: ${commonStyles.jc_c};
  border-radius: ${commonStyles.sec_br};
  margin-top: 2rem;
  padding: 10px 18px;
  background-color: ${(props) =>
    props.themeColor ? colorProvider.blackThree : colorProvider.navBackground};
  border: ${(props) =>
    props.themeColor
      ? `1px solid ${colorProvider.blackThree}`
      : commonStyles.com_b};
  @media (max-width: 510px) {
    align-items: flex-start;
  }
  @media (max-width: 410px) {
    padding: 10px 12px;
  }
`;
export const SecondInnerFirst = styled.div`
  display: ${commonStyles.ds_fx};
  align-items: ${commonStyles.jc_c};
  gap: 0.7rem;
  flex: 1;
  justify-content: space-between;
  margin-left: 1rem;
  @media (max-width: 585px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;
export const CompleteImageBox = styled.div`
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.jc_c};
  align-items: ${commonStyles.jc_c};

  img {
    height: 50px;
    width: 50px;
    object-fit: ${commonStyles.ob_con};
    @media (max-width: 410px) {
      height: 40px;
      width: 40px;
    }
  }
`;
export const CompleteContentBox = styled.div`
  display: ${commonStyles.ds_fx};
  flex-direction: ${commonStyles.fd_col};
  justify-content: ${commonStyles.jc_fs};
  align-items: ${commonStyles.jc_fs};

  .row-de {
    display: ${commonStyles.ds_fx};
    align-items: ${commonStyles.jc_c};

    @media (max-width: 500px) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 5px;
    }
  }

  .quiz-title {
    font-size: ${fontProvider.zeroEig};
    font-weight: ${fontWeight.six};
    color: ${(props) =>
      props.themeColor
        ? colorProvider.white
        : props.watchColor
        ? colorProvider.blackSix
        : colorProvider.blackCol};
    text-transform: none !important;
    @media (max-width: 410px) {
      font-size: ${fontProvider.zeroSix};
    }
  }
  .retake-text {
    font-size: ${fontProvider.zeroEig};
    font-weight: ${fontWeight.five};
    color: ${(props) =>
      props.themeColor ? colorProvider.greyThree : colorProvider.dateColor};
    margin-left: 5px;
    display: flex;
    @media (max-width: 500px) {
      margin: 0;
      font-size: ${fontProvider.zeroSev};
    }
  }
  .desc-text {
    font-size: ${fontProvider.zeroSev};
    font-weight: ${fontWeight.five};
    color: ${(props) =>
      props.themeColor ? colorProvider.greyThree : colorProvider.greyThree};
    margin-top: 5px;
    @media (max-width: 410px) {
      font-size: ${fontProvider.zeroSix};
    }
  }

  img {
    height: 20px;
    width: 20px;
    object-fit: ${commonStyles.ob_con};
    margin-left: 7px;

    @media (max-width: 500px) {
      height: 15px;
      width: 15px;
      object-fit: contain;
    }
  }
`;
export const ViewResult = styled.div`
  cursor: pointer;
  p {
    font-size: ${fontProvider.zeroEig};
    font-weight: ${fontWeight.six};
    color: ${(props) =>
      props.themeColor
        ? colorProvider.navLink
        : props.watchColor
        ? colorProvider.greyFive
        : colorProvider.navLink};
    @media (max-width: 410px) {
      font-size: 0.75rem;
    }
  }
`;
export const ParentTable = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* @media (max-width: 769px) {
    margin-bottom: 6rem;
  } */
`;
export const TableBox = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: 1rem;
  text-transform: capitalize;

  .ant-table-thead .ant-checkbox .ant-checkbox-inner {
    height: 24px !important;
    width: 24px !important;
    background-size: contain !important;
    background-color: transparent !important;
    border: 2px solid transparent !important;
  }
  td.ant-table-cell.ant-table-cell-scrollbar {
    background-color: ${(props) =>
      props.themeColor ? "#1F242F" : "#FFFFFF"} !important;
    box-shadow: none !important;
  }
  .ant-table-thead .ant-checkbox .ant-checkbox-inner:after {
    height: 21px !important;
    width: 21px !important;
    background-color: transparent !important;
    background-size: contain !important;
    border-color: transparent !important;
  }
  .ant-checkbox-checked .ant-checkbox-inner:after {
    margin-left: 7px !important;
    margin-top: 0.8px;
    transform: rotate(0deg) scale(1) translate(-50%, -50%) !important;
  }
  && .ant-table-column-sorter {
    color: ${(props) =>
      props.themeColor
        ? "#FFFFFF"
        : "#0C111D"} !important; // Use !important to override existing styles
  }

  &&&
    :where(.css-dev-only-do-not-override-p7e5j5).ant-table-wrapper
    .ant-table-pagination-right {
    background-color: ${(props) =>
      props.themeColor ? "#0C111D" : "#FFFFFF"} !important;
    border-top: ${(props) =>
      props.themeColor ? "1px solid #1F242F" : "1px solid #D7DAE0"};
    border-radius: 0 0 8px 8px !important;
  }
  &&& :where(.css-p7e5j5).ant-table-wrapper .ant-table-pagination-right {
    background-color: ${(props) =>
      props.themeColor ? "#0C111D" : "#FFFFFF"} !important;
    border-top: ${(props) =>
      props.themeColor ? "1px solid #1F242F" : "1px solid #D7DAE0"};
    border-radius: 0 0 8px 8px !important;
  }

  /* @media (max-width: 769px) {
    display: none;
  } */
`;
export const CertificateContainer = styled.div`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  flex-direction: ${commonStyles.fd_col};
  justify-content: ${commonStyles.jc_c};
  align-items: ${commonStyles.jc_c};
`;
export const ImageCon = styled.div`
  width: 100%;
  img {
    width: ${commonStyles.wid};
    object-fit: ${commonStyles.ob_con};
  }

  @media print {
    display: none;
  }
`;
export const CirButtonRow = styled.div`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.jc_sb};
  align-items: ${commonStyles.jc_c};
  margin-top: 1.5rem;
  padding: 1rem;
  border-radius: 12px;
  border: ${(props) => (props.themeColor ? `none` : commonStyles.com_b)};
  background-color: ${(props) =>
    props.themeColor ? "transparent" : colorProvider.white};
  position: relative;

  @media (max-width: 500px) {
    flex-direction: column;
    gap: 1rem;
  }
`;
export const ShareBox = styled.div`
  width: 30%;
  display: flex;
  /* display: ${(props) => (props.isOpen ? "flex" : "none")}; */
  flex-direction: column;
  justify-content: left;
  align-items: center;
  position: absolute;
  background-color: ${(props) => (props.themeColor ? "#0C111D" : "#ffffff")};
  left: 0;
  top: 0;
  margin-top: -9.2rem;
  margin-left: 13rem;
  box-shadow: 0px 2px 4px -1px #0000000f, 0px 4px 6px -1px #0000001a;
  border: ${(props) =>
    props.themeColor ? "1px solid #333741" : "1px solid #edeef1"};
  border-radius: 8px;
  z-index: 9999;
`;
export const MenuItem = styled.a`
  text-decoration: none;
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: ${(props) =>
    props.themeColor ? "1px solid #333741" : "1px solid #edeef1"};
  padding: 0.5rem 1rem;
  gap: 0.5rem;

  :hover {
    background-color: grey;
    cursor: pointer;
  }

  img {
    height: 22px;
    width: 22px;
    object-fit: contain;
  }

  p {
    font-weight: 600;
    font-size: 0.8rem;
    color: ${(props) => (props.themeColor ? "#F5F5F6" : "#363A44")};
    text-transform: capitalize;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
export const LightButton = styled.div`
  width: 48%;
  position: relative;
  button {
    width: 100%;
    display: ${commonStyles.ds_fx};
    justify-content: ${commonStyles.jc_c};
    align-items: ${commonStyles.jc_c};
    outline: none;
    border: ${(props) => (props.themeColor ? `none` : commonStyles.com_b)};
    background-color: ${(props) =>
      props.themeColor ? colorProvider.darkBlue : colorProvider.white};
    box-shadow: 0px 1px 2px 0px #1018280d;
    border-radius: ${commonStyles.sec_br};
    font-weight: ${fontWeight.six};
    font-size: ${fontProvider.zeroEig};
    color: ${(props) =>
      props.themeColor ? colorProvider.white : colorProvider.comLink};
    padding: ${fontProvider.zeroSev};
    gap: 7px;
    cursor: ${commonStyles.pointer};
    @media (max-width: 500px) {
      font-size: ${fontProvider.zeroSev};
    }
  }

  img {
    height: 18px;
    width: 18px;
    object-fit: ${commonStyles.ob_con};
  }
  @media (max-width: 500px) {
    width: 100%;
  }
`;
export const OrangeButton = styled.div`
  width: 48%;

  button {
    cursor: ${commonStyles.pointer};
    outline: none;
    border: none;
    width: 100%;
    display: ${commonStyles.ds_fx};
    justify-content: ${commonStyles.jc_c};
    align-items: ${commonStyles.jc_c};
    background-color: ${(props) =>
      props.themeColor ? colorProvider.navLink : colorProvider.navLink};
    box-shadow: 0px 1px 2px 0px #1018280d;
    border-radius: ${commonStyles.sec_br};
    padding: ${fontProvider.zeroSev};
    gap: 7px;
    font-weight: ${fontWeight.six};
    font-size: ${fontProvider.zeroEig};
    color: ${(props) =>
      props.themeColor ? colorProvider.white : colorProvider.white};
    @media (max-width: 500px) {
      font-size: ${fontProvider.zeroSev};
    }
  }
  img {
    height: 18px;
    width: 18px;
    object-fit: ${commonStyles.ob_con};
  }
  @media (max-width: 500px) {
    width: 100%;
  }
`;
export const DownloadButton = styled.div`
  width: 48%;

  a {
    text-decoration: none;
    cursor: ${commonStyles.pointer};
    outline: none;
    border: none;
    width: 100%;
    display: ${commonStyles.ds_fx};
    justify-content: ${commonStyles.jc_c};
    align-items: ${commonStyles.jc_c};
    background-color: ${(props) =>
      props.themeColor ? colorProvider.navLink : colorProvider.navLink};
    box-shadow: 0px 1px 2px 0px #1018280d;
    border-radius: ${commonStyles.sec_br};
    padding: ${fontProvider.zeroSev};
    gap: 7px;
    font-weight: ${fontWeight.six};
    font-size: ${fontProvider.zeroEig};
    color: ${(props) =>
      props.themeColor ? colorProvider.white : colorProvider.white};
    @media (max-width: 500px) {
      font-size: ${fontProvider.zeroSev};
    }
  }
  button {
    text-decoration: none;
    cursor: ${commonStyles.pointer};
    outline: none;
    border: none;
    width: 100%;
    display: ${commonStyles.ds_fx};
    justify-content: ${commonStyles.jc_c};
    align-items: ${commonStyles.jc_c};
    background-color: ${(props) =>
      props.themeColor ? colorProvider.navLink : colorProvider.navLink};
    box-shadow: 0px 1px 2px 0px #1018280d;
    border-radius: ${commonStyles.sec_br};
    padding: ${fontProvider.zeroSev};
    gap: 7px;
    font-weight: ${fontWeight.six};
    font-size: ${fontProvider.zeroEig};
    color: ${(props) =>
      props.themeColor ? colorProvider.white : colorProvider.white};
    @media (max-width: 500px) {
      font-size: ${fontProvider.zeroSev};
    }
  }
  img {
    height: 18px;
    width: 18px;
    object-fit: ${commonStyles.ob_con};
  }
  @media (max-width: 500px) {
    width: 100%;
  }
`;

export const MainCardHead = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  flex-wrap: wrap;
`;

export const CardContainer = styled.div`
  width: 32%;
  background-color: ${(props) =>
    props.themeColor ? "#0C111D" : "#FFFFFF"} !important;
  border-radius: 8px;
  height: 160px;
  padding: 1.2rem;

  @media (max-width: 1258px) {
    width: 48%;
  }

  @media (max-width: 580px) {
    width: 100%;
  }
`;

export const CardInner = styled.div`
  width: 100%;
  display: flex;
  align-items: start;
  justify-content: start;

  img {
    width: 30px;
    height: 30px;
    object-fit: contain;
    border-radius: 50%;
    border: 1.5px solid ${colorProvider.blackTwo};
  }
`;

export const CardDetails = styled.div`
  width: 100%;
`;

export const CardTitle = styled.div`
  font-size: 1rem;
  width: 100%;
  padding: 0 0 0 0.5rem;
  font-weight: 500;
  color: ${(props) =>
    props.themeColor ? colorProvider.white : colorProvider.blackCol};
`;

export const CardSubTitle = styled.div`
  font-size: 0.8rem;
  width: 100%;
  padding: 0.2rem 0 0 0.5rem;
  font-weight: 500;
  color: #f05d24;
`;

export const CardBottom = styled.div`
  width: 100%;
`;

export const CardBtDetails = styled.div`
  display: flex;
  align-items: start;
  justify-content: start;
  margin: 0.8rem;

  img {
    width: 14px;
    height: 14px;
    object-fit: contain;
  }
`;

export const PhoneNumber = styled.div`
  font-size: 0.8rem;
  padding: 0 0 0 0.5rem;
  color: ${(props) =>
    props.themeColor ? colorProvider.white : colorProvider.blackCol};
`;

export const CardMail = styled.div`
  font-size: 0.8rem;
  padding: 0 0 0 0.5rem;
  color: ${(props) =>
    props.themeColor ? colorProvider.white : colorProvider.blackCol};
  text-transform: lowercase;
`;

export const CardLocation = styled.div`
  font-size: 0.8rem;
  padding: 0 0 0 0.5rem;
  color: ${(props) =>
    props.themeColor ? colorProvider.white : colorProvider.blackCol};
`;
export const LottieLoadCon = styled.div`
  width: 100%;
  min-height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;

  .div {
  }
`;
