import React, { useState } from "react";
import * as AS from "./styles/ArrivalStyled";

import demoImage1 from "./assets/images/demoImage1.png";
import demoImage2 from "./assets/images/demoImage2.png";
import demoImage3 from "./assets/images/demoImage3.png";
import demoImage4 from "./assets/images/demoImage4.png";
import demoImage5 from "./assets/images/demoImage5.png";
import demoImage6 from "./assets/images/demoImage6.png";
import rightIconSlide from "./assets/images/rightIconSlide.png";
import leftIconSlide from "./assets/images/leftIconSlide.png";
import lightTitleRight from "./assets/images/lightTitleRight.png";
import lightTitleLeft from "./assets/images/lightTitleLeft.png";
import { setOnBoarding } from "./store/action";

const OnBoarding = ({ selectLangJson, handleIsOpen, dispatch, theme }) => {
  const screenOrder = [
    "firstScreen",
    "secondScreen",
    "thirdScreen",
    "fourthScreen",
    "fifthScreen",
    "sixthScreen",
  ];

  const demoImages = [
    demoImage1, // Image for first screen
    demoImage2, // Image for second screen
    demoImage3, // Image for third screen
    demoImage4, // Image for fourth screen
    demoImage5, // Image for fifth screen
    demoImage6, // Image for sixth screen
  ];

  const titleOrder = [
    selectLangJson?.welcome_to_workflix,
    selectLangJson?.course_onboad_head,
    selectLangJson?.support_onboard_head,
    selectLangJson?.language_onboard_head,
    selectLangJson?.dark_head,
    selectLangJson?.profile_head,
  ];

  const descOrder = [
    selectLangJson?.welcome_msg,
    selectLangJson?.course_msg,
    selectLangJson?.support_msg,
    selectLangJson?.language_msg,
    selectLangJson?.dark_msg,
    selectLangJson?.profile_msg,
  ];

  const [currentScreenIndex, setCurrentScreenIndex] = useState(0);

  const handleNext = () => {
    setCurrentScreenIndex((prev) =>
      prev < screenOrder.length - 1 ? prev + 1 : prev
    );
  };

  const handleBack = () => {
    setCurrentScreenIndex((prev) => (prev > 0 ? prev - 1 : prev));
  };

  const handleScreenClick = (screen) => {
    const index = screenOrder.indexOf(screen);
    if (index !== -1) setCurrentScreenIndex(index);
  };

  const resetScreens = () => {};

  return (
    <AS.ArrContainer themeColor={theme}>
      {screenOrder.map((screen, index) =>
        currentScreenIndex === index ? (
          <AS.ArrInnerContainer key={screen} themeColor={theme}>
            <AS.ArrFirst>
              <img src={demoImages[index]} alt="" />
            </AS.ArrFirst>
            <AS.ArrSecond>
              <AS.DotRow themeColor={theme}>
                <button onClick={handleBack} disabled={index === 0}>
                  <img src={theme ? leftIconSlide : lightTitleLeft} alt="" />
                </button>
                {screenOrder.map((dotScreen, dotIndex) => (
                  <AS.DotCom themeColor={theme}
                    key={dotScreen}
                    onClick={() => handleScreenClick(dotScreen)}
                    isActive={dotIndex === currentScreenIndex}
                  ></AS.DotCom>
                ))}
                <button
                  onClick={handleNext}
                  disabled={index === screenOrder.length - 1}
                >
                  <img src={theme ? rightIconSlide : lightTitleRight} alt="" />
                </button>
              </AS.DotRow>
              <AS.ContentBox themeColor={theme}>
                <AS.FirstContent themeColor={theme}>
                  <div className="title">{titleOrder[index]}</div>
                  <div className="desc">{descOrder[index]}</div>
                </AS.FirstContent>
                <AS.SecondContent themeColor={theme}>
                  {index < screenOrder.length - 1 ? (
                    <div className="orangebox" onClick={handleNext}>
                      {index === 0
                        ? selectLangJson?.get_started
                        : selectLangJson?.next}
                    </div>
                  ) : (
                    <div
                      className="orangebox"
                      onClick={() => {
                        handleIsOpen(false);
                        dispatch(setOnBoarding(false));
                      }}
                    >
                      {selectLangJson?.close}
                    </div>
                  )}
                  {index < screenOrder.length - 1 ? (
                    <div
                      className="skip-text"
                      onClick={() => {
                        handleIsOpen(false);
                        dispatch(setOnBoarding(false));
                      }}
                    >
                      {selectLangJson?.skip}
                    </div>
                  ) : (
                    ""
                  )}
                </AS.SecondContent>
              </AS.ContentBox>
            </AS.ArrSecond>
          </AS.ArrInnerContainer>
        ) : null
      )}
    </AS.ArrContainer>
  );
};

export default OnBoarding;
