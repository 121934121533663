import styled, { keyframes } from "styled-components/macro";

export const ControlsContainer = styled.div`
  /* background-color: rgba(0, 0, 0, 0.6); */
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  flex-direction: column;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  /* padding-bottom: 0.2rem; */
  border-radius: 0 0 12px 12px;
  /* height: ${(props) =>
    props.isFullScreen ? "100%" : props.isCourseConent ? "100%" : "100%"}; */

  @media (max-width: 769px) {
    /* background-color: black;
      z-index: -1; */
  }
`;
export const TopContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px 20px;
`;
export const MidContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;

  i {
    font-size: 1.4rem;
  }
`;
export const IconButton = styled.div`
  padding: 0 10px;
  color: #7b2cbf;
  @media (max-width: 600px) {
    padding: 0px 0px;
  }
  img {
    height: 25px !important;
    width: 25px !important;
    object-fit: contain;
    cursor: pointer;
    border: none !important;
    @media (max-width: 451px) {
      height: 18px !important;
      width: 18px !important;
    }
  }
`;
const slideIn = keyframes`
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`;
export const VolumeSlider = styled.input.attrs({ type: "range" })`
  width: ${(props) => (props.visible ? "70px" : "5px")};
  height: 5px;
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: 15px;
  background: linear-gradient(
    to right,
    #f05d24 0%,
    #f05d24 ${(props) => props.value}%,
    #fff ${(props) => props.value}%,
    #fff 100%
  );
  outline: none;

  opacity: ${(props) => (props.visible ? 1 : 0)};
  transition: opacity 0.5s ease;
  pointer-events: ${(props) => (props.visible ? "auto" : "none")};

  &::-webkit-slider-thumb {
    width: 15px;
    height: 15px;
    background: #f05d24;
    border-radius: 50%;
    cursor: pointer;
    appearance: none;
  }

  &.visible {
    display: block;
    animation: ${slideIn} 0.5s forwards; // Use the keyframes
  }
`;
export const IconButtonsContainer = styled.div`
  display: flex;
  align-items: center;
`;
export const IconButtonsContainers = styled.div`
  display: flex;
  align-items: center;
`;
export const IconButtons = styled.div`
  padding: 0 10px;
  color: #7b2cbf;

  img {
    height: 25px !important;
    width: 25px !important;
    object-fit: contain;
    cursor: pointer;
    border: none !important;
    @media (max-width: 451px) {
      height: 18px !important;
      width: 18px !important;
    }
  }
`;
export const ParentControlBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 0 1.2rem; */
`;

export const ControlBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
  background-color: #1f242fa3;
  padding: 7px 20px;
  margin-top: -7px;
  /* border-radius: 0 0 8px 8px; */
  /* background-color: yellow; */
  @media (max-width: 600px) {
    padding: 7px 5px;
  }
`;
export const InnerControlBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
  padding-right: 1rem;
  @media (max-width: 600px) {
    padding-right: 0rem;
  }
`;
export const SliderContainer = styled.div`
  width: ${(props) =>
    props.visible
      ? props?.isFullScreen
        ? "73%"
        : "73%"
      : props?.isFullScreen
      ? "83%"
      : "83%"};
  /* width: 50%; */
  display: flex;
  /* padding: 0 16px; */
  align-items: center;
  justify-content: end;

  /* @media (max-width: 1369px) {
    width: ${(props) =>
    props.visible
      ? props?.isFullScreen
        ? "73%"
        : "73%"
      : props?.isFullScreen
      ? "83%"
      : "83%"};
  }
  @media (max-width: 1251px) {
    width: ${(props) =>
    props.visible
      ? props?.isFullScreen
        ? "73%"
        : "73%"
      : props?.isFullScreen
      ? "81%"
      : "81%"};
  }
  @media (max-width: 1131px) {
    width: ${(props) =>
    props.visible
      ? props?.isFullScreen
        ? "73%"
        : "73%"
      : props?.isFullScreen
      ? "79%"
      : "79%"};
  } */
`;
export const SliderContainerTwo = styled.div`
  width: ${(props) =>
    props.visible
      ? props?.isFullScreen
        ? "80%"
        : "62%"
      : props?.isFullScreen
      ? "85%"
      : "73%"};
  /* width: 50%; */
  display: flex;
  /* padding: 0 16px; */
  align-items: center;
  justify-content: end;
  @media (max-width: 880px) {
    width: 55%;
  }
`;
export const InnerControls = styled.div`
  display: flex;
  padding: 10px 0;
  align-items: center;
  width: ${(props) => (props.visible ? "15%" : "15%")};

  span {
    color: #f05d24;
    font-size: 0.8rem;
    margin-left: 10px;
  }
  @media (max-width: 1369px) {
    width: auto;
  }
`;
export const SecondControl = styled.div`
  display: flex;
  align-items: center;
`;
export const SeekSlider = styled.input.attrs({ type: "range" })`
  width: 100%;
  height: 5px;
  outline: none;
  opacity: 1;
  transition: opacity 1s;
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: 15px;
  background: linear-gradient(
    to right,
    #f05d24 0%,
    #f05d24 ${(props) => props.value}%,
    #fff ${(props) => props.value}%,
    #fff 100%
  );

  &::-webkit-slider-thumb {
    /* width: 15px;
    height: 5px; */
    width: 15px;
    height: 15px;
    /* background: #f05d24; */
    border-radius: 50%;
    background: #f05d24;
    /* border-radius: 50%; */
    cursor: pointer;
    appearance: none;
    display: ${(props) => props.value > 0 ? "flex" : "none"};
  }
`;
// export const SeekSlider = styled.input.attrs({ type: "range" })`
//   width: 100%;
//   height: 5px;
//   outline: none;
//   opacity: 1;
//   transition: opacity 1s;
//   -webkit-appearance: none;
//   -moz-appearance: none;
//   border-radius: 15px;
//   background: linear-gradient(
//     to right,
//     #f05d24 0%,
//     #f05d24 ${(props) => props.maxValue}%,  /* Stop at maxWatchedTime */
//     #ddd ${(props) => props.maxValue}%, 
//     #ddd 100%
//   );

//   &::-webkit-slider-thumb {
//     width: 15px;
//     height: 15px;
//     border-radius: 50%;
//     background: #f05d24;
//     cursor: ${(props) => (props.isForwardDisabled ? "not-allowed" : "pointer")};
//     pointer-events: ${(props) => (props.isForwardDisabled ? "none" : "auto")}; /* Disable forward clicks */
//   }
// `;

export const Seektime = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-width: 100px;
  margin-left: ${(props) => (props.visible ? "1rem" : "0")};

  p {
    font-size: 0.9rem;
    color: white;
    font-weight: 500;
    @media (max-width: 451px) {
      font-size: 10px;
    }
  }

  @media (max-width: 451px) {
    min-width: 60px;
  }
`;
export const BottomContainer = styled.div`
  width: auto;
  display: flex;
  justify-content: end;
  align-items: center;
  position: relative;
  /* padding-right: 1rem; */

  p {
    /* border: ${(props) =>
      props.isFK ? "1px solid red" : "1px solid transparent"}; */
    border-radius: 8px;
    /* padding: 5px 8px; */
    /* font-style: ${(props) => (props.isFK ? "italic" : "normal")}; */
    font-size: 0.8rem;
    font-weight: 500;
    color: white;
    cursor: pointer;
    word-break: keep-all !important;
    @media (max-width: 451px) {
      font-size: 0.8rem;
      padding-left: 5px;
    }
  }

  img {
    height: 30px !important;
    width: 30px !important;
    object-fit: contain;
    margin-left: 1rem;
    cursor: pointer;
    border: none !important;
    @media (max-width: 451px) {
      height: 18px !important;
      width: 18px !important;
    }
  }
`;
export const QualityLabel = styled.p`
  border: ${(props) =>
    props.isFK ? "1px solid red" : "1px solid transparent"} !important;
  border-radius: 8px !important;
  padding: 5px 8px !important;
  font-style: ${(props) => (props.isFK ? "italic" : "normal")} !important;
  font-size: 0.8rem;
  font-weight: 500;
  color: white;
  cursor: pointer;
  word-break: keep-all !important;
  @media (max-width: 451px) {
    font-size: 0.8rem;
    padding-left: 5px;
  }
`;
export const QualityContainer = styled.div`
  min-width: 150px;
  max-height: 200px;
  overflow-y: auto;
  border-radius: 8px;
  background: linear-gradient(
    360deg,
    #000d1a 0%,
    #000d1a 24.69%,
    rgba(0, 13, 26, 0.88) 63.02%,
    rgba(0, 13, 26, 0.64) 100%
  );
  position: absolute;
  bottom: 55px;
  /* margin-top: -0rem; */

  p {
    /* border-bottom: 1px solid #333741; */
    font-size: 0.7rem !important;
    font-weight: 500 !important;
    color: white !important;
    text-align: left !important;
    text-transform: capitalize !important;
    padding: 12px 0 !important;
    padding-left: 1rem !important;
    word-break: keep-all !important;

    &:hover {
      background-color: #3f3e3c;
      color: white;
    }
  }
`;
