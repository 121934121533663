import React, { useState, useRef, useEffect } from "react";
import * as CS from "../../../styles/CourseViewStyled";
import * as SS from "../../../styles/SectionStyled";
import courseBanner from "../../../assets/images/courseBanner.jpeg";
import playButton from "../../../assets/images/playButton.png";
import ReactHlsPlayer from "react-hls-player";
import MediaPlayer from "./MediaPlayer";
import axios from "../../../utils/axios";
import { Episode } from "../../../utils/api";
import { useSelector } from "react-redux";
import Modal from "../../../components/Modal/Modal";
import * as MS from "../../../styles/ModelStyled";
import {
  setBannerThumb,
  setButtonName,
  setCompleteProgressBar,
  setEpisodeCompleted,
  setEpisodeId,
  setErrorPop,
  setErrorPopMsg,
  setRefreshRestart,
  setVideoCount,
  setVideoPlay,
} from "../../../store/action";
import { get } from "lodash";
import Lottie from "lottie-react";
import groovyWalkAnimation from "../../../assets/images/lottie/congrats.json";
import { colorProvider } from "../../../utils/StylePropertiy";
import crossIcon from "../../../assets/images/crossIcon.png";
import ModalTwo from "../../../components/Modal/ModalTwo";

const BannerVew = ({
  courseId,
  theme,
  handleIsChange,
  dispatch,
  handleIsChanging,
  isChanging,
  navigate,
  handleCourseMenu,
  isRestartCourse,
  handleRefresh
}) => {
  const videoPlay = useSelector((state) => state?.videoPlay);
  const videoCount = useSelector((state) => state?.videoCount);
  const bannerThumb = useSelector((state) => state?.bannerThumb);
  const quizEpisode = useSelector((state) => state?.quizEpisode);
  const episodeCompleted = useSelector((state) => state?.episodeCompleted);
  const selectLang = useSelector((state) => state?.selectLang);
  const episodeSelectLang = useSelector((state) => state?.episodeSelectLang);
  const selectLangJson = useSelector((state) => state?.selectLangJson);
  const languageId = useSelector((state) => state?.languageId);
  const buttonName = useSelector((state) => state?.buttonName);
  const isActionDisabled = useSelector((state) => state?.isActionDisabled);
  const refreshRestart = useSelector((state) => state?.refreshRestart);
  const dynamicRoutes = useSelector((state) =>
    state?.dynamicRoutes ? `/${state?.dynamicRoutes}` : ""
  );
  const [showsucess, setShowsucess] = useState(false);
  const [policyPopup, setPolicyPopup] = useState(true);
  const [isOpenPolicy, setIsOpenPolicy] = useState(false);
  const [modalMsg, setModalMsg] = useState("");
  const startLearning = async () => {
    let url = Episode.startLearning;
    try {
      const { data } = await axios.post(url, {
        courseId: courseId,
        languageId: episodeSelectLang,
      });
      if (data?.data) {
        // console.log(data?.data?.restart_course_status, "data?.data?.restart_course_status data?.data?.restart_course_status")
        // setIsRestartCourse(data?.data?.restart_course_status);
        dispatch(setCompleteProgressBar(data?.data?.courseCompleteProgressBar));
        dispatch(setButtonName(data?.data?.button_name));
        dispatch(setEpisodeCompleted(data?.data?.COURSE_COMPLETED));
        let comData = {
          categorie: get(data?.data, "course_categorie", ""),
          courseTitle: get(data?.data, "course_name", ""),
          thumbUrl: get(data?.data, "course_baise_infos.thumbnailImage", ""),
          bannerTrailerImageNameUrl: get(
            data?.data,
            "course_baise_infos.bannerTrailerImageNameUrl",
            ""
          ),
          basicDescription: get(
            data?.data,
            "course_baise_infos.baiseDescription",
            ""
          ),
          client_logo: get(data?.data, "clientLogo", ""),
        };
        dispatch(setBannerThumb(comData));
        if (data?.data?.episodeId) {
          dispatch(setEpisodeId(data?.data?.episodeId));
          dispatch(setVideoPlay(true));
        }
        if (data?.data?.QUIZ_SCREEN) {
          navigate(`${dynamicRoutes}/learner/quiz`, {
            state: {
              _id: data?.data?.quiz?._id,
            },
          });
        }
        if (quizEpisode) {
          console.log("test 1");
          if (data?.data?.NEXT_EPISODE) {
            console.log("test 2");
            console.log(data?.data, "Test Epusode id");
            dispatch(setEpisodeId(data?.data?.episodeId));
            dispatch(setVideoPlay(true));
          }
        }
        if (data?.data?.COURSE_COMPLETED) {
          setShowsucess(false);
          setTimeout(() => {
            setShowsucess(false);
          }, 10000);
          dispatch(setEpisodeId(null));
          dispatch(setVideoPlay(false));
          if (data?.data?.VIEW_POLICY_DOCUMENTS) {
            navigate(`${dynamicRoutes}/learner/courses/policy-document`);
          }
        }
        if (data?.data?.VIEW_POLICY_DOCUMENTS) {
          navigate(`${dynamicRoutes}/learner/courses/policy-document`);
          dispatch(setEpisodeId(null));
          dispatch(setVideoPlay(false));
          setIsOpenPolicy(true);
          setModalMsg(data?.data?.model?.message);
        }
      }
    } catch (error) {
      console.log(error.response.data);
      dispatch(setVideoPlay(false));
      if (error.response && error.response.status === 400) {
        if (error.response.data?.data?.HOME_SCREEN) {
          dispatch(setVideoPlay(false));
        }
      }
      if (error.response && error.response.status === 400) {
        dispatch(setErrorPop(true));
        dispatch(setErrorPopMsg(error?.response?.data?.message));
      }
    }
  };

  console.log(isRestartCourse, "isRestartCourse  isRestartCourse isRestartCourse")

  const getVideoName = (e) => {
    if (e === "start_Learning") {
      return selectLangJson?.start_learning;
    } else if (e === "resume_learning") {
      return selectLangJson?.resume_learning;
    } else if (e === "complete_course") {
      return selectLangJson?.completed_course;
    } else if (e === "update_course") {
      return selectLangJson?.upcoming_course;
    } else {
      return e;
    }
  };

  const handleRestartCourse = async () => {
    try {
      let url = `${Episode.restartCourse}${courseId}`;
      const { data } = await axios.get(url);
      console.log(data);
      startLearning();
      dispatch(setRefreshRestart(refreshRestart + 1))
    } catch (error) {
      if (error.response && error.response.status === 400) {
        dispatch(setErrorPop(true));
        dispatch(setErrorPopMsg(error?.response?.data?.message));
      }
    }
  };
  return (
    <>
      {showsucess ? (
        <CS.LottieContainer>
          <Lottie animationData={groovyWalkAnimation} loop={true} />
        </CS.LottieContainer>
      ) : (
        ""
      )}

      {videoPlay ? (
        <CS.BackImage>
          <MediaPlayer
            theme={theme}
            courseId={courseId}
            handleStartLearning={startLearning}
            isPlay={videoPlay}
            handleIsChanging={handleIsChanging}
            isChanging={isChanging}
            handleCourseMenu={handleCourseMenu}
          />
        </CS.BackImage>
      ) : (
        <CS.BackImage>
          <img src={get(bannerThumb, "bannerTrailerImageNameUrl", "")} alt="" />
          <div className="transBack">
            <SS.BannerTitle>
              <p>{get(bannerThumb, "courseTitle", "")}</p>
            </SS.BannerTitle>
            <CS.BannerDesc>
              <p
                dangerouslySetInnerHTML={{
                  __html: get(bannerThumb, "basicDescription", ""),
                }}
              />
            </CS.BannerDesc>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "1rem",
              }}
            >
              <SS.WatchButton
                className={isActionDisabled ? "not-allowed" : ""}
                onClick={() => {
                  if (!isActionDisabled) {
                    startLearning();
                    dispatch(setVideoCount(videoCount + 1));
                  }
                }}
              >
                <img src={playButton} alt="" />

                <p>{getVideoName(buttonName)}</p>
              </SS.WatchButton>
              {isRestartCourse ? (
                <SS.WatchButton
                  onClick={() => {
                    handleRestartCourse();
                  }}
                >
                  <img src={playButton} alt="" />

                  <p>{selectLangJson?.restart_course}</p>
                </SS.WatchButton>
              ) : (
                ""
              )}
            </div>
          </div>
        </CS.BackImage>
      )}

      <ModalTwo
        maxWidth={"400px"}
        isOpen={isOpenPolicy}
        // isClose={handleClose}
        backColor={colorProvider.darkBlue}
        component={
          <MS.LeaderContainer themeColor={theme}>
            <MS.LeaderHeader
              style={{ border: "none", justifyContent: "end" }}
              themeColor={theme}
            >
              <MS.CrossButton
                onClick={() => {
                  setIsOpenPolicy(false);
                }}
              >
                <img src={crossIcon} alt="" />
              </MS.CrossButton>
            </MS.LeaderHeader>
            <MS.HeaderBox themeColor={theme}>
              <p className="head-title">{modalMsg}</p>
            </MS.HeaderBox>
          </MS.LeaderContainer>
        }
      />
    </>
  );
};

export default BannerVew;
