import React, { useEffect, useRef, useState } from "react";
import * as SS from "../../styles/SidebarStyled";
import * as NS from "../../styles/NavStyled";
import * as CS from "../../styles/CommonStyled";
import mainLogo from "../../assets/images/rainLogo.png";
import darkLogo from "../../assets/images/rainLogoDark.png";
import crossGrey from "../../assets/images/crossGrey.png";
import { SidebarData } from "./SidebarData";
import logoutLogo from "../../assets/images/logoutIcon.png";
import downArrowSide from "../../assets/images/downArrowSide.png";
import darkArrowSide from "../../assets/images/darkArrowSide.png";
import AvatarIcon from "../../assets/images/Avatar.png";
import profileDemo from "../../assets/images/profileDemo.png";
import accountIcon from "../../assets/images/accountIcon.png";
import {
  setAccessToken,
  setErrorPop,
  setErrorPopMsg,
  setIsDark,
  setOnBoarding,
  setShowVideoo,
  setSidebarIsOpens,
  setUserData,
  setVideoPlay,
} from "../../store/action";
import * as HS from "../../styles/HeaderStyled";
import lightSun from "../../assets/images/light.png";
import lightMoon from "../../assets/images/dark.png";
import { useSelector } from "react-redux";
import * as LS from "../../styles/LearnerStyled";
import Switch from "../../components/Switch/Switch";
import { Episode, GetNotification } from "../../utils/api";
import logoutIconGrey from "../../assets/images/logoutIconGrey.png";
import axios from "../../utils/axios";
import { jwtDecode } from "jwt-decode";
import { convertSecondsToTimeString } from "../../utils/Helpers";

const Sidebar = ({ theme, navigate, dispatch }) => {
  const access_token = useSelector((state) => state?.access_token);
  const [isOpen, setIsOpen] = useState(true);
  const [isActive, setIsActive] = useState(null);
  const profileData = useSelector((state) => state?.profileData);
  // const [sidebarIsOpen, setSidebarIsOpen] = useState(true);
  const sidebarIsOpens = useSelector((state) => state?.sidebarIsOpen);
  const sidebarToggle = useSelector((state) => state?.isSidebarToggle);
  const selectLangJson = useSelector((state) => state?.selectLangJson);
  const [sidebarIsOpen, setSidebarIsOpen] = useState(sidebarIsOpens);
  const videoPlay = useSelector((state) => state?.videoPlay);
  const courseId = useSelector((state) => state?.courseId);
  const episodeId = useSelector((state) => state?.episodeId);
  const episodeSelectLang = useSelector((state) => state?.episodeSelectLang);
  const timeStamp = useSelector((state) => state?.timeStamp);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const dynamicRoutes = useSelector((state) =>
    state?.dynamicRoutes ? `/${state?.dynamicRoutes}` : ""
  );
  const [opens, setOpens] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const decoded = jwtDecode(access_token);

  const profileRef = useRef(null);
  const handleConfirm = () => {
    setDeleteSuccess(true);
    setIsOpen(false);
    setOpens(false);

    setSidebarIsOpen(false);
    dispatch(setSidebarIsOpens(false));
    setTimeout(() => {
      setDeleteSuccess(false);
      localStorage.removeItem("rainLearnerData");
      localStorage.removeItem("rainLearnerToken");
      localStorage.removeItem("rainLearnerLang");
      localStorage.removeItem("languagesObj");
      localStorage.removeItem("isActionDisabled");
      localStorage.removeItem("rainRMUserData");
      localStorage.removeItem("rainLearnerCourseId");
      localStorage.removeItem("rainClientUserData");
      localStorage.removeItem("rainRoute");
      dispatch(setUserData(""));
      dispatch(setShowVideoo(false));
      dispatch(setUserData(""));
      dispatch(setOnBoarding(false));
      dispatch(setUserData(""));
      dispatch(setAccessToken(""));
      window.location.ref = "/";
    }, 2000);
  };
  const removeImpersonate = async () => {
    try {
      let url = GetNotification.removeImpersonate;
      const { data } = await axios.get(url);
      console.log(data?.data);

      if (data?.data) {
        handleConfirm();
        if (decoded?.clientAdmin) {
          if (data?.data.redirect) window.location.href = data?.data.redirect;
        } else {
          window.close();
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        handleConfirm();
      }
    }
  };

  const handleThemeChange = () => {};

  console.log(profileData, "profileData profileData");

  const nameFuntion = async (e) => {
    let url = Episode.timeSubmit;
    const formValues = {
      courseId: courseId,
      episodeId: episodeId,
      languageId: episodeSelectLang,
      last_time_stamp: convertSecondsToTimeString(e),
    };

    try {
      const { data } = await axios.post(url, formValues);
    } catch (error) {
      dispatch(setVideoPlay(false));
      if (error.response && error.response.status === 400) {
        dispatch(setErrorPop(true));
        dispatch(setErrorPopMsg(error?.response?.data?.message));
      }
    }
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (profileRef.current && !profileRef.current.contains(event.target)) {
        setIsProfileOpen(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [profileRef]);
  return (
    <div>
      <SS.SidebarContainerResp
        sidebarIsOpen={sidebarIsOpens}
        themeColor={theme}
      >
        <SS.InnerSidebar themeColor={theme}>
          <SS.LogoListBox>
            <SS.LogoRow>
              <SS.MainLogo>
                <img
                  src={theme ? darkLogo : mainLogo}
                  alt=""
                  style={{ display: isOpen ? "block" : "none" }}
                />
              </SS.MainLogo>
              <SS.ArrowLogoResp
                src={crossGrey}
                alt=""
                onClick={() => {
                  dispatch(setSidebarIsOpens(false));
                }}
              />
            </SS.LogoRow>
            {/* {SidebarData?.map((val, ind) => {
              const isSubMenuActive = isActive === val;
              return (
                <SS.MenuBox key={ind}>
                  <SS.MenuRow
                    themeColor={theme}
                    activeColor={val.activeTab?.includes(
                      window.location.pathname
                    )}
                    onClick={() => {
                      if (val.subMenu) {
                        setIsActive(isSubMenuActive ? null : val);
                      } else {
                        setIsActive(null);
                        navigate(val.path);
                        setSidebarIsOpen(false);
                        dispatch(setSidebarIsOpens(false));
                        // dispatch(setTableLength([]));
                      }
                    }}
                  >
                    <div
                      className="row-wise"
                      onClick={() => {
                        if (val.subMenu) {
                          return "";
                        } else {
                          // navigate(val.path);
                        }
                      }}
                    >
                      <img
                        src={
                          val.activeTab?.includes(window.location.pathname)
                            ? val.activeIcon
                            : val.icon
                        }
                        alt=""
                      />
                      {isOpen && val.title}
                    </div>
                    {val.subMenu && (
                      <img
                        className="arrow-img"
                        src={
                          val.activeTab?.includes(window.location.pathname)
                            ? isActive
                              ? darkArrowSide
                              : downArrowSide
                            : downArrowSide
                        }
                        alt=""
                      />
                    )}
                  </SS.MenuRow>
                  {isActive && (
                    <SS.SubMenuBox
                      isActive={isSubMenuActive}
                      themeColor={theme}
                    >
                      {val.subMenu?.map((subItem, subIndex) => (
                        <SS.ActiveSub
                          themeColor={theme}
                          key={subIndex}
                          activeColor={subItem.activePathTab?.includes(
                            window.location.pathname
                          )}
                          onClick={() => {
                            // navigate(subItem.pathName);
                            // dispatch(setTableLength([]));
                            setSidebarIsOpen(false);
                            dispatch(setSidebarIsOpens(false));
                          }}
                        >
                          {subItem.label}
                        </SS.ActiveSub>
                      ))}
                    </SS.SubMenuBox>
                  )}
                </SS.MenuBox>
              );
            })} */}
            <SS.MenuBox>
              <SS.MenuRow
                themeColor={theme}
                activeColor={SidebarData[0].activeTab?.includes(
                  window.location.pathname
                )}
                onClick={() => {
                  setIsActive(null);
                  navigate(SidebarData[0].path);
                  setSidebarIsOpen(false);
                  dispatch(setSidebarIsOpens(false));
                }}
              >
                <div className="row-wise">
                  <img
                    src={
                      SidebarData[0].activeTab?.includes(
                        window.location.pathname
                      )
                        ? SidebarData[0].activeIcon
                        : SidebarData[0].icon
                    }
                    alt=""
                  />
                  {isOpen && SidebarData[0].title}
                </div>
              </SS.MenuRow>
            </SS.MenuBox>

            <SS.MenuBox>
              <SS.MenuRow
                themeColor={theme}
                activeColor={SidebarData[1].activeTab?.includes(
                  window.location.pathname
                )}
                onClick={() => {
                  setIsActive(null);
                  navigate(`${dynamicRoutes}/learner/course`);
                  setSidebarIsOpen(false);
                  dispatch(setSidebarIsOpens(false));
                }}
              >
                <div className="row-wise">
                  <img
                    src={
                      SidebarData[1].activeTab?.includes(
                        window.location.pathname
                      )
                        ? SidebarData[1].activeIcon
                        : SidebarData[1].icon
                    }
                    alt=""
                  />
                  {isOpen && SidebarData[1].title}
                </div>
              </SS.MenuRow>
            </SS.MenuBox>

            <SS.MenuBox>
              <SS.MenuRow
                themeColor={theme}
                activeColor={SidebarData[2].activeTab?.includes(
                  window.location.pathname
                )}
                onClick={() => {
                  if (isActive) {
                    setIsActive(null);
                  } else {
                    setIsActive(SidebarData[2]);
                  }
                }}
              >
                <div className="row-wise">
                  <img
                    src={
                      SidebarData[2].activeTab?.includes(
                        window.location.pathname
                      )
                        ? SidebarData[2].activeIcon
                        : SidebarData[2].icon
                    }
                    alt=""
                  />
                  {isOpen && SidebarData[2].title}
                </div>
                <img
                  className="arrow-img"
                  src={isActive ? darkArrowSide : downArrowSide}
                  alt=""
                />
              </SS.MenuRow>
              {isActive && (
                <SS.SubMenuBox isActive={isActive} themeColor={theme}>
                  <SS.ActiveSub
                    themeColor={theme}
                    activeColor={SidebarData[2].subMenu[0].activePathTab?.includes(
                      window.location.pathname
                    )}
                    onClick={() => {
                      setSidebarIsOpen(false);
                      dispatch(setSidebarIsOpens(false));
                    }}
                  >
                    {SidebarData[2].subMenu[0].label}
                  </SS.ActiveSub>
                  <SS.ActiveSub
                    themeColor={theme}
                    activeColor={SidebarData[2].subMenu[1].activePathTab?.includes(
                      window.location.pathname
                    )}
                    onClick={() => {
                      setSidebarIsOpen(false);
                      dispatch(setSidebarIsOpens(false));
                    }}
                  >
                    {SidebarData[2].subMenu[1].label}
                  </SS.ActiveSub>
                  <SS.ActiveSub
                    themeColor={theme}
                    activeColor={SidebarData[2].subMenu[2].activePathTab?.includes(
                      window.location.pathname
                    )}
                    onClick={() => {
                      setSidebarIsOpen(false);
                      dispatch(setSidebarIsOpens(false));

                      dispatch(setOnBoarding(true));
                      dispatch(setVideoPlay(false));
                      if (videoPlay) {
                        nameFuntion(timeStamp);
                      }
                    }}
                  >
                    {SidebarData[2].subMenu[2].label}
                  </SS.ActiveSub>
                </SS.SubMenuBox>
              )}
            </SS.MenuBox>
          </SS.LogoListBox>
          <SS.BottomContainer>
            <HS.ComMenuBoxCon
              themeColor={theme}
              style={{
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <img src={theme ? lightMoon : lightSun} alt="" />
                {selectLangJson.light_theme}
              </div>
              <LS.DefaultToggle>
                <Switch
                  rounded={true}
                  isToggled={theme}
                  bgColorChecked="#F6591C"
                  roundColorChecked="#ffffff"
                  border={theme ? "1px solid transparent" : "1px solid #D7DAE0"}
                  themeColor={theme}
                  wiDth={"40px"}
                  bgColorUnChecked={theme ? "#1F242F" : "#F5F5F6"}
                  roundColorUnChecked="#ffffff"
                  onToggle={() => {
                    const newTheme = !theme;
                    localStorage.setItem("rainTheme", newTheme); // Save theme to local storage
                    dispatch(setIsDark(newTheme));
                  }}
                />
              </LS.DefaultToggle>
            </HS.ComMenuBoxCon>
            <SS.LogOutBoxResp
              themeColor={theme}
              onClick={() => {
                setIsProfileOpen(true);
              }}
            >
              <NS.AvatarSidebar
              // onClick={() => {
              //   setIsProfileOpen(!isProfileOpen);
              // }}
              >
                <img src={profileData?.profileImage || AvatarIcon} alt="" />
              </NS.AvatarSidebar>
              <CS.TitleColumn>
                <CS.ColumnTitle
                  style={{ textTransform: "capitalize" }}
                  themeColor={theme}
                >
                  {" "}
                  {profileData?.userName}
                </CS.ColumnTitle>
                <CS.ColumnEmail
                  style={{
                    whiteSpace: "nowrap",
                    width: "170px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    marginTop: "3px",
                  }}
                  themeColor={theme}
                >
                  {profileData?.email}
                </CS.ColumnEmail>
              </CS.TitleColumn>
            </SS.LogOutBoxResp>
            {isProfileOpen ? (
              <HS.AccountBoxTwo themeColor={theme} ref={profileRef}>
                <HS.ProfileRow themeColor={theme}>
                  <HS.ProfileBox>
                    <img
                      src={profileData?.profileImage || profileDemo}
                      alt=""
                    />
                  </HS.ProfileBox>
                  <HS.NameBox themeColor={theme}>
                    <HS.NameTitle themeColor={theme}>
                      {profileData?.userName}
                    </HS.NameTitle>
                    <HS.NameEmail themeColor={theme}>
                      {profileData?.email}
                    </HS.NameEmail>
                  </HS.NameBox>
                </HS.ProfileRow>
                <HS.ComMenuBoxNoBorder
                  themeColor={theme}
                  to={`${dynamicRoutes}/learner/profile-details`}
                  onClick={() => {
                    setSidebarIsOpen(false);
                    dispatch(setSidebarIsOpens(false));
                    dispatch(setVideoPlay(false));
                    if (videoPlay) {
                      nameFuntion(timeStamp);
                    }
                  }}
                >
                  <img src={accountIcon} alt="" />
                  {selectLangJson?.account_settings}
                </HS.ComMenuBoxNoBorder>
                {/* <HS.ComMenuBox
                    themeColor={theme}
                    to="/learner/profile-details"
                    onClick={() => {
                      setIsProfileOpen(false);
                      dispatch(setVideoPlay(false));
                    }}
                  >
                    <img src={lightConfigure} alt="" />
                    {selectLangJson.settings}
                  </HS.ComMenuBox> */}
                {profileData?.switchToUser && (
                  <HS.ComMenuBoxCon
                    themeColor={theme}
                    style={{
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <img src={accountIcon} alt="" />
                      {selectLangJson.switch_to_user}
                    </div>
                    <LS.DefaultToggle>
                      <Switch
                        rounded={true}
                        isToggled={isOpen}
                        bgColorChecked="#F6591C"
                        roundColorChecked="#ffffff"
                        border={
                          theme ? "1px solid transparent" : "1px solid #D7DAE0"
                        }
                        themeColor={theme}
                        wiDth={"40px"}
                        bgColorUnChecked={theme ? "#1F242F" : "#F5F5F6"}
                        roundColorUnChecked="#ffffff"
                        onToggle={() => {
                          setIsOpen(!isOpen);
                          setSidebarIsOpen(false);
                          dispatch(setSidebarIsOpens(false));
                          removeImpersonate();
                        }}
                      />
                    </LS.DefaultToggle>
                  </HS.ComMenuBoxCon>
                )}

                <HS.ComMenuBox
                  themeColor={theme}
                  onClick={handleConfirm}
                  style={{ border: "none" }}
                >
                  <img src={logoutIconGrey} alt="" />
                  {selectLangJson.log_out}
                </HS.ComMenuBox>
              </HS.AccountBoxTwo>
            ) : (
              ""
            )}
          </SS.BottomContainer>
        </SS.InnerSidebar>
        <SS.InnerSidebarBlank></SS.InnerSidebarBlank>
      </SS.SidebarContainerResp>
    </div>
  );
};

export default Sidebar;
