import {
  USER_DATA,
  IS_DARK,
  ACCESS_MODULE,
  IS_TOGGLE,
  SIDEBAR_TOGGLE,
  DELETE_LENGTH,
  SIDEBAR_TOGGLE_RESP,
  IS_MODAL,
  ACCESS_TOKEN,
  COURSE_ID,
  IS_MUTED,
  SIDE_CONTENT_DATA,
  EPISODEID,
  COMPLETEPROGRESS,
  VIDEOPLAY,
  VIDEOCOUNT,
  BANNERTHUMB,
  EPISODECOMPLETED,
  EPISODEREFRESH,
  VOLUMEVALUE,
  BUTTONNAME,
  QUIZEPISODE,
  MENUREFRESH,
  LANGUAGEID,
  SELECTLANG,
  SELECTLANGJSON,
  LANGUAGES,
  EPISODELANGUAGES,
  EPISODESELECTLANGUAGE,
  PROFILEUPDATE,
  EPISODELENGTH,
  TEMPLATEID,
  ISACTIONDISABLED,
  PROFILEDATA,
  ONBOARDING,
  COURSEMENUDATA,
  DYNAMICROUTES,
  ERROROBJ,
  ERRORPOPMSG,
  TIMESTAMP,
  SHOWVIDEO,
  NOTIFICATIONDOT,
  NEWUSER,
  ISFORMTYPE,
  USERTYPERESP,
  REFRESHRESTART,
} from "./actionTypes";

const setUserData = (data) => {
  return {
    type: USER_DATA,
    data,
  };
};
const setIsDark = (data) => {
  return {
    type: IS_DARK,
    data,
  };
};
const setIsAccessModule = (data) => {
  return {
    type: ACCESS_MODULE,
    data,
  };
};
const setIsToggle = (data) => {
  return {
    type: IS_TOGGLE,
    data,
  };
};

const setSidebarToggle = (data) => {
  return {
    type: SIDEBAR_TOGGLE,
    data,
  };
};
const setTableLength = (data) => {
  return {
    type: DELETE_LENGTH,
    data,
  };
};
const setSidebarIsOpens = (data) => {
  return {
    type: SIDEBAR_TOGGLE_RESP,
    data,
  };
};
const setIsModal = (data) => {
  return {
    type: IS_MODAL,
    data,
  };
};
const setAccessToken = (data) => {
  return {
    type: ACCESS_TOKEN,
    data,
  };
};
const setCourseId = (data) => {
  return {
    type: COURSE_ID,
    data,
  };
};
const setIsMuted = (data) => {
  return {
    type: IS_MUTED,
    data,
  };
};
const setSideContentData = (data) => {
  return {
    type: SIDE_CONTENT_DATA,
    data,
  };
};
const setEpisodeId = (data) => {
  return {
    type: EPISODEID,
    data,
  };
};
const setCompleteProgressBar = (data) => {
  return {
    type: COMPLETEPROGRESS,
    data,
  };
};
const setVideoPlay = (data) => {
  return {
    type: VIDEOPLAY,
    data,
  };
};
const setVideoCount = (data) => {
  return {
    type: VIDEOCOUNT,
    data,
  };
};
const setBannerThumb = (data) => {
  return {
    type: BANNERTHUMB,
    data,
  };
};
const setEpisodeCompleted = (data) => {
  return {
    type: EPISODECOMPLETED,
    data,
  };
};
const setEpisodeRefresh = (data) => {
  return {
    type: EPISODEREFRESH,
    data,
  };
};
const setVolumeValue = (data) => {
  return {
    type: VOLUMEVALUE,
    data,
  };
};
const setButtonName = (data) => {
  return {
    type: BUTTONNAME,
    data,
  };
};
const setQuizEpisode = (data) => {
  return {
    type: QUIZEPISODE,
    data,
  };
};
const setMenuRefresh = (data) => {
  return {
    type: MENUREFRESH,
    data,
  };
};
const setLanaguageId = (data) => {
  return {
    type: LANGUAGEID,
    data,
  };
};
const setSelectLang = (data) => {
  return {
    type: SELECTLANG,
    data,
  };
};
const setSelectLangJson = (data) => {
  return {
    type: SELECTLANGJSON,
    data,
  };
};
const setLanguages = (data) => {
  return {
    type: LANGUAGES,
    data,
  };
};
const setEpisodeLanguages = (data) => {
  return {
    type: EPISODELANGUAGES,
    data,
  };
};
const setEpisodedSelectLang = (data) => {
  return {
    type: EPISODESELECTLANGUAGE,
    data,
  };
};
const setUpdateProfile = (data) => {
  return {
    type: PROFILEUPDATE,
    data,
  };
};
const setEpisodeLength = (data) => {
  return {
    type: EPISODELENGTH,
    data,
  };
};
const setTemplateId = (data) => {
  return {
    type: TEMPLATEID,
    data,
  };
};
const setIsActionDisabled = (data) => {
  return {
    type: ISACTIONDISABLED,
    data,
  };
};
const setProfileData = (data) => {
  return {
    type: PROFILEDATA,
    data,
  };
};
const setOnBoarding = (data) => {
  return {
    type: ONBOARDING,
    data,
  };
};
const setCourseMenuData = (data) => {
  return {
    type: COURSEMENUDATA,
    data,
  };
};
const setDynamicRoutes = (data) => {
  return {
    type: DYNAMICROUTES,
    data,
  };
};
const setErrorPop = (data) => {
  return {
    type: ERROROBJ,
    data,
  };
};
const setErrorPopMsg = (data) => {
  return {
    type: ERRORPOPMSG,
    data,
  };
};
const setTimeStamp = (data) => {
  return {
    type: TIMESTAMP,
    data,
  };
};
const setShowVideoo = (data) => {
  return {
    type: SHOWVIDEO,
    data,
  };
};
const setNotificationDot = (data) => {
  return {
    type: NOTIFICATIONDOT,
    data,
  };
};
const setNewUser = (data) => {
  return {
    type: NEWUSER,
    data,
  };
};
const setIsFormType = (data) => {
  return {
    type: ISFORMTYPE,
    data,
  };
};
const setUserTypeResp = (data) => {
  return {
    type: USERTYPERESP,
    data,
  };
};
const setRefreshRestart = (data) => {
  return {
    type: REFRESHRESTART,
    data,
  };
};
export {
  setUserData,
  setIsDark,
  setIsAccessModule,
  setIsToggle,
  setSidebarToggle,
  setTableLength,
  setSidebarIsOpens,
  setIsModal,
  setAccessToken,
  setCourseId,
  setIsMuted,
  setSideContentData,
  setEpisodeId,
  setCompleteProgressBar,
  setVideoPlay,
  setVideoCount,
  setBannerThumb,
  setEpisodeCompleted,
  setEpisodeRefresh,
  setVolumeValue,
  setButtonName,
  setQuizEpisode,
  setMenuRefresh,
  setLanaguageId,
  setSelectLang,
  setSelectLangJson,
  setLanguages,
  setEpisodeLanguages,
  setEpisodedSelectLang,
  setUpdateProfile,
  setEpisodeLength,
  setTemplateId,
  setIsActionDisabled,
  setProfileData,
  setOnBoarding,
  setCourseMenuData,
  setDynamicRoutes,
  setErrorPop,
  setErrorPopMsg,
  setTimeStamp,
  setShowVideoo,
  setNotificationDot,
  setNewUser,
  setIsFormType,
  setUserTypeResp,
  setRefreshRestart
};
