import styled from "styled-components/macro";
import {
  commonStyles,
  colorProvider,
  fontProvider,
  fontWeight,
} from "../utils/StylePropertiy";

export const ArrContainer = styled.div`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.jc_c};
  align-items: ${commonStyles.ai_c};
  box-shadow: 0px 1.22px 2.45px 0px #0000000d;
  border-radius: ${commonStyles.sec_br};
`;
export const ArrInnerContainer = styled.div`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  flex-direction: ${commonStyles.fd_col};
  justify-content: ${commonStyles.jc_c};
  align-items: ${commonStyles.ai_c};
  box-shadow: 0px 1.22px 2.45px 0px #0000000d;
  border-color: ${(props) =>
    props.themeColor
      ? `1px solid ${colorProvider.blackSix}`
      : `1px solid ${colorProvider.blackSix}`};
  background-color: ${(props) =>
    props.themeColor ? colorProvider.darkBlue : colorProvider.darkBlue};
  border-radius: ${commonStyles.sec_br};
`;
export const ArrFirst = styled.div`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.jc_c};
  align-items: ${commonStyles.ai_c};
  padding: 1.5rem;
  background-color: #333741;

  img {
    width: ${commonStyles.wid};
    object-fit: ${commonStyles.ob_con};
  }
`;
export const ArrSecond = styled.div`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  flex-direction: ${commonStyles.fd_col};
  justify-content: ${commonStyles.jc_c};
  align-items: ${commonStyles.ai_c};
  background-color: ${(props) =>
    props.themeColor ? colorProvider.darkBlue : colorProvider.darkBlue};
  padding: 1.5rem 1.5rem 1.5rem 1.5rem;
`;
export const DotCom = styled.div`
  height: 5px;
  width: 5px;
  border-radius: 50%;
  cursor: pointer;
  background-color: ${(props) =>
    props.isActive ? colorProvider.greyFive : colorProvider.blackTwo};
`;
export const DotRow = styled.div`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.jc_c};
  align-items: ${commonStyles.ai_c};
  gap: 0.2rem;

  button{
    background-color: transparent;  
    border: none;
    margin: 0 10px;
    cursor: pointer;
  }

  img {
    height: 22px;
    width: 22px;
    object-fit: contain;
  }
`;
export const ContentBox = styled.div`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  flex-direction: ${commonStyles.fd_col};
  margin-top: ${fontProvider.oneThree};
`;
export const FirstContent = styled.div`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  flex-direction: ${commonStyles.fd_col};
  justify-content: ${commonStyles.jc_fs};
  align-items: ${commonStyles.ai_fs};

  .title {
    font-family: EB Garamond;
    font-size: ${fontProvider.oneFive};
    color: ${(props) =>
      props.themeColor ? colorProvider.white : colorProvider.white};
    font-weight: ${fontWeight.six};
    margin-bottom: ${fontProvider.zeroEig};
  }
  .desc {
    font-size: ${fontProvider.zeroEig};
    color: ${(props) =>
      props.themeColor ? colorProvider.whiteTwo : colorProvider.whiteTwo};
    font-weight: ${fontWeight.four};
    line-height: 18px;
  }
`;
export const SecondContent = styled.div`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  align-items: ${commonStyles.ai_c};
  margin-top: ${fontProvider.oneSix};
  gap: 1.5rem;

  .orangebox {
    border-radius: 9px;
    font-size: ${fontProvider.zeroEig};
    color: ${(props) =>
      props.themeColor ? colorProvider.whiteTwo : colorProvider.whiteTwo};
    background-color: ${(props) =>
      props.themeColor ? colorProvider.navLink : colorProvider.navLink};
    font-weight: ${fontWeight.five};
    padding: 0.7rem 1.2rem;
    cursor: ${commonStyles.pointer};
  }

  .skip-text {
    font-size: ${fontProvider.zeroEig};
    color: ${(props) =>
      props.themeColor ? colorProvider.whiteThree : colorProvider.whiteThree};
    font-weight: ${fontWeight.five};
    cursor: ${commonStyles.pointer};
  }
`;
